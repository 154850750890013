import Axios from "axios";

export default class AuthService {

    getLogin(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/auth/login`, data).then(res => res);
    }

    outLogin(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/auth/salir`, data).then(res => res);
    }

    reset(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/reset/usuario`, data).then(res => res);
    }

    cambiar(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/cambiar`, data).then(res => res);
    }

    // Para enlazar nuevos usuarios con 2FA activado en su primer login
    enlazar2FA(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/2fa/enlazar`, data).then(res => res);
    }

    // Para verificar el codigo 2fa al momento del login
    verificar2FA(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/2fa/verificar`, data).then(res => res);
    }

}
