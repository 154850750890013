import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { InformacionPJ } from './pj/InformacionPJ';
import { DatosPJ } from './pj/DatosPJ';
import { addLocale } from 'primereact/api';
import { InputComponent } from '../../components/InputComponent';
import { Clientepj } from '../../models/ClientePj';
import { GenericComponent } from '../../components/GenericComponent';
import { useForm } from 'react-hook-form';
import { ClientePJService } from '../../service/ClientePJService';
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { InfEconomica } from './pj/InfEconomica';
import { SocAccPj } from './pj/SocAccPj';
import { PepPj } from './pj/PepPj';
import { OrigenLic } from './pj/OrigenLic';
import { InfoApliada } from './pj/InfoApliada';
import { Declaracion } from './pj/Declaracion';
import { useToken } from '../../components/useToken';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FileComponent } from '../../components/FileComponent';
import { FiltroMultiempresa } from '../../components/FiltroMultiempresa';
import { Generico } from "../../components/Generico";
import { Dialog } from 'primereact/dialog';
import { AnexosComponent } from '../../components/AnexosComponent';

export const ClientePerJu = () => {

    const generic = new GenericComponent();
    const omodel = new Clientepj();
    const { accionesTbl } = Generico();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const [clientespj, setClientespj] = useState(null);
    const clientepjSrv = new ClientePJService();
    const [cliente, setCliente] = useState(null);
    const defaultValues = generic.default(model);
    const { control, formState: { errors } } = useForm({ defaultValues });
    const [dialogClientepj, setDialogClientepj] = useState(false);
    const toast = useRef(null);
    const [nuevo, setNuevo] = useState(true);
    const [id, setId] = useState(null);
    const { getPerss, getRol } = useToken();
    const [empresa, setEmpresa] = useState(null);
    const [siCliente, setSiCliente] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: clientespj, omodel, generic, errors, control, permss, id: cliente?.id });
    const [activeIndex, setActiveIndex] = useState(0);
    const { dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePj', toast, permss, id: id });
    const [catalogos, setCatalogos] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [urls, setUrls] = useState(null);

    useEffect(() => {
        setUrls({
            urlListado: 'ficha/clientepj',
            urlDownload: 'ficha/clientepj/descarga',
            urlUpload: 'ficha/clientepj/store',
            udelete: 'ficha/clientepj/eliminar-anexo',
            uactual: 'ficha/clientepj/editar',
            uenviar: 'ficha/clientepj/enviar',
            uaprobar: 'ficha/clientepj/aprobar',
        });
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            verificarFicha();
        }
    }, [lazyParams]);

    const editFicha = () => {
        if (catalogos == null) {
            clientepjSrv.newClientepj({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setActiveIndex(0);
                setDialogClientepj(true);
                setNuevo(cliente == null);
                setdialogIns(true);
                setId(cliente?.id);
            });
        } else {
            setActiveIndex(0);
            setDialogClientepj(true);
            setNuevo(cliente == null);
            setdialogIns(true);
            setId(cliente?.id);
        }
    }

    addLocale(generic.idioma, generic.esFormat);

    const verificarFicha = () => {
        clientepjSrv.verificar().then(res => {
            if (res.data != null) {
                openNew({ id: res.data.id, nemonico: res.data.nemonico });
            }
            else {
                openNew(null);
            }
        });
    }

    const loadLazyData = () => {
        setLoading(true);
        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        delete _lazyParams.empresa;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        if (empresa) {
            _lazyParams.empresa = empresa;
        }
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        clientepjSrv.getClientepj({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setClientespj(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
            }
            if (res.data.cats) {
                setCatalogos(res.data.cats);
            }
        });
    }

    const openNew = (reg = null) => {
        if (catalogos == null) {
            clientepjSrv.newClientepj({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setActiveIndex(0);
                setDialogClientepj(true);
                setNuevo(reg == null);
                setdialogIns(true);
                setId(reg?.id);
                if (reg?.id) {
                    setCliente({ id: reg.id, nemonico: reg.nemonico });
                }
            });
        } else {
            setActiveIndex(0);
            setDialogClientepj(true);
            setNuevo(reg == null);
            setdialogIns(true);
            setId(reg?.id);
            const _cliente = cliente ?? {};
            _cliente.nemonico = reg?.nemonico ?? cliente?.nemonico ?? null;
            _cliente.id = reg?.id ?? cliente?.id ?? null;
            setCliente(_cliente);
        }
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCliente()
        });
    }

    const deleteCliente = () => {
        clientepjSrv.eliminarClientepj(cliente?.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const regresar = () => {
        setActiveIndex(0);
        setDialogClientepj(false);
        loadLazyData();
    };

    const handleNext = (valor) => {
        if (activeIndex < 7) {
            setActiveIndex(activeIndex + 1);
        }
        setId(valor);
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    // Menu del formulario Cliente Persona Jurídica
    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!id?.nemonico || id.nemonico !== 'FINALIZADO')) {
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha() } });
        }
        if (permss?.consultar) {
            items.push({ label: 'Anexos', icon: 'pi pi-folder-open', command: () => { setDialogSubir(true) } });
        }
        if (permss?.eliminar && (!id?.nemonico || id.nemonico !== 'FINALIZADO')) {
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });
        }
        return [{ label: 'Opciones', items: items }];
    };

    const reiniciar = () => {
        if (!siCliente) {
            loadLazyData();
        }
        setDialogClientepj(false);
        setDialogSubir(false);
    }

    const verificacion = (data) => {
        loadLazyData();
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogClientepj && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(clientespj, omodel, 'Reporte_persona_juridica', 'LISTADO CLIENTES PERSONA JURIDICA')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action01: accionesTbl, reg: setCliente, items: itemsMenu() })}
                </Card></>
            }
            {dialogClientepj &&
                <Panel header="INFORMACIÓN DE PERSONAS JURÍDICAS" style={{ width: '100%' }}>
                    <div>
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Sección 1">
                                <InformacionPJ toast={toast} siCliente={siCliente} next={handleNext} catalogos={catalogos} id={cliente?.id} editFicha={openNew} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 2" disabled={cliente?.id == null}>
                                <DatosPJ toast={toast} id={cliente?.id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 3" disabled={cliente?.id == null}>
                                <InfEconomica toast={toast} id={cliente?.id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 4" disabled={cliente?.id === null}>
                                <SocAccPj toast={toast} id={cliente?.id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 5" disabled={cliente?.id === null}>
                                <PepPj toast={toast} id={cliente?.id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 6" disabled={cliente?.id === null}>
                                <OrigenLic toast={toast} id={cliente?.id} catalogos={catalogos} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 7" disabled={cliente?.id === null}>
                                <InfoApliada toast={toast} id={cliente?.id} nuevo={nuevo} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 8" disabled={cliente?.id === null}>
                                <Declaracion toast={toast} id={cliente?.id} nuevo={nuevo} setDialogClientepj={setDialogClientepj} permss={permss} cancelar={getPerss() === undefined}
                                    setdialogIns={setdialogIns} urls={urls} estado={cliente?.nemonico} reiniciar={reiniciar} />
                            </TabPanel>
                        </TabView>
                    </div>
                </Panel>
            }
            <br />
            {dialogClientepj && <Button label="Regresar" icon="pi pi-replay" onClick={regresar} />}
            {dialogInstruccion(omodel.mTitle, omodel.mensaje)}
            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <AnexosComponent generic={generic} tipo="clientePj" urls={urls} idp={cliente?.id} toast={toast} permss={permss} setDialogSubir={setDialogSubir} reiniciar={reiniciar}
                    loadLazyData={loadLazyData} verificacion={verificacion} estado={cliente?.nemonico} />
            </Dialog>
        </>
    )
}
