import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { UsuarioRuc } from "../../models/UsuarioRuc";
import { useForm } from "react-hook-form";
import { ListaMasivaService } from "../../service/ListaMasivaService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Generico } from "../../components/Generico";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

export const Masivo = () => {
    const generic = new GenericComponent();
    const omodel = new UsuarioRuc();
    const [permss, setPermss] = useState(null);
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [usuarios, setUsuarios] = useState(null);
    const listaService = new ListaMasivaService();
    const [item, setItem] = useState(null);
    const toast = useRef(null);
    const [archivo, setArchivo] = useState(null);
    const [dialogAdjunto, setDialogAdjunto] = useState(false);
    const [dialogUsuario, setDialogUsuario] = useState(false);
    const [loadFile, setLoadFile] = useState(false);
    let fileRef = useRef(null);
    const [carga, setCarga] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inText } = InputComponent({ registers: usuarios, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            listaService.getCarga({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setUsuarios(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogUsuario(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            listaService.newCarga(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogUsuario(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            listaService.editarCarga(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogUsuario(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const edit = () => {
        listaService.consultarCarga(item?.id).then(res => {
            reset(generic.renewValues(res.data, omodel.model));
            setDialogUsuario(true);
        });
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarCarga()
        });
    }

    const eliminarCarga = () => {
        listaService.eliminar(item?.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { edit() } });
        if (permss?.actualizar)
            items.push({ label: 'Volver a procesar', icon: 'pi pi-qrcode', command: () => { } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    const nuevoProceso = () => {
        setDialogAdjunto(true);
        setArchivo(null);
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button icon="pi pi-plus" severity="secondary" onClick={openNew} tooltip="Registrar identificación" />&nbsp;
                <Button icon="pi pi-upload" severity="warning" type="button" onClick={nuevoProceso} tooltip="Cargar listado" />&nbsp;
                <Button icon="pi pi-file-pdf" tooltip="Descargar todos los informes en formato PDF" onClick={() => descargarZip('pdf')} loading={loadFile} />&nbsp;
                <Button icon="pi pi-file-excel" severity="success" tooltip="Descargar todos los informes en formato Microsoft Excel" onClick={() => descargarZip('xlsx')} loading={loadFile} />
            </div>
        );
    }

    const onSubmitFile = (data) => {
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo);
        setCarga(true);
        listaService.procesarLista(formData).then(res => {
            setCarga(false);
            setArchivo(null);
            setDialogAdjunto(false);
            generic.funcSuccess(res.data, toast);
            loadLazyData();
        }).catch(error => {
            setCarga(false);
            generic.funcError(error, toast, setError);
        });
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (file.size > size) {
            toast.current.show({
                severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) +
                    " Mb. El archivo actual tiene: " + (file.size / 1024000).toFixed(3) + " Mb.", life: 3000
            });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (e.files.length <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo permitido para el archivo es: ' + (size / 1024000).toFixed(2) + " Mb.", life: 3000 });
            return;
        }
        if (e.files[0].size > size) {
            toast.current.show({
                severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) +
                    " Mb. El archivo actual tiene: " + (e.files[0].size / 1024000).toFixed(3) + " Mb.", life: 3000
            });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const descargarZip = (tipo) => {
        setLoadFile(true);
        listaService.procesar({ tipo: tipo }).then(res => {
            setLoadFile(false);
            const filename = res.headers.get('x-consulta');
            generic.downloadPdf(res.data, filename);
        }).catch(error => {
            setLoadFile(false);
            generic.funcError(error, toast, setError);
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
                {table({ action01: accionesTbl, reg: setItem, items: itemsMenu() })}
            </Card>

            <Dialog visible={dialogUsuario} header={getValues('id') == null ? "Nueva identificación" : "Editar identificación"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogUsuario(false)} style={{ width: '400px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('ruc', {}, 12)}
                        {inText('nombres', {}, 12)}
                    </div>
                    {generic.buttonsForms(setDialogUsuario)}
                </form>
            </Dialog>

            <Dialog visible={dialogAdjunto} header="Subir listado a procesar" modal className="p-fluid modal-ssize" onHide={e => setDialogAdjunto(false)} style={{ width: '300px' }}>
                <form onSubmit={handleSubmit(onSubmitFile)}>
                    <div className="formgrid grid">
                        <span style={{ fontSize: '10px' }} >
                            Observaciones:
                            <ul>
                                <li>La primera columna debe ser la identificación.</li>
                                <li>La segunda columna debe ser el nombre completo.</li>
                                <li>La primera fila debe ser el título de cada columna.</li>
                                <li>Solo se toma en cuenta la primera hoja.</li>
                            </ul>
                        </span>
                        <div className="field col-12">
                            <label><b>Seleccione un archivo*</b></label><br />
                            <FileUpload name="file" accept=".xlsx" maxFileSize={parseInt(process.env.REACT_APP_FILE_SIZE)} uploadHandler={invoiceUploadHandler} mode="basic"
                                customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />
                            {archivo?.name}
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col" style={{ textAlign: 'center' }}>
                            <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="submit" icon="pi pi-check" className="p-button-warning" loading={carga} />
                        </div>
                        <div className="field col">
                            <Button label="Cerrar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" loading={carga} onClick={() => setDialogAdjunto(false)} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
