import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useToken } from '../components/useToken';
import { Controller, useForm } from 'react-hook-form';
import { useRef } from 'react';
import { useHistory } from "react-router-dom";
import AuthService from '../service/AuthService';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { Resetear } from './administracion/Resetear';
import { Toast } from 'primereact/toast';
import { useState } from "react";
import { Codigo2FA } from '../components/Codigo2FA';
import '../components/estilo.css';

export const Login = () => {
    const { getNombre, saveToken } = useToken();
    const history = useHistory();
    const authService = new AuthService();
    const toast = useRef(null);
    const [codigo2FAModal, setCodigo2FAModal] = useState({ visible: false, modo: "VERIFICACION", qrCodeUrl: null });
    const [temp2FAToken, setTemp2FAToken] = useState(null);
    const [temp2FASecret, setTemp2FASecret] = useState(null);
    const [loading, setLoading] = useState(false);

    const defaultValues = {
        usuario: '',
        clave: ''
    }
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
    const message = useRef();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error"><br />{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        setLoading(true);
        authService.getLogin(data).then(res => {
            if (res.data.vincular_2fa) {
                // Flujo de vinculacion
                setTemp2FAToken(res.data.token_2fa_enlace);
                setTemp2FASecret(res.data.secret);
                setCodigo2FAModal({ visible: true, modo: "ENLACE", qrCodeUrl: res.data.qr_code_url });
            } else if (res.data.verificar_2fa) {
                // Flujo de verificación
                setTemp2FAToken(res.data.token_2fa);
                setCodigo2FAModal({ visible: true, modo: "VERIFICACION", qrCodeUrl: null });
            } else {
                // Flujo sin 2FA
                saveToken(res.data.data);
                if (getNombre()) history.push("/");
            }
        }).catch(error => {
            try {
                message.current.show({ severity: 'error', content: error.response.data.message });
            } catch (err) {
                message.current.show({ severity: 'error', content: 'Error al ingresar. Consulte al administrador' });
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const enlazar2FA = (codigo2fa) => {
        return authService.enlazar2FA({ token_2fa_enlace: temp2FAToken, codigo_2fa: codigo2fa, secret: temp2FASecret })
            .then(res => {
                saveToken(res.data.data);
                setCodigo2FAModal(false);
                setTemp2FAToken(null);
                setTemp2FASecret(null);
                if (getNombre()) history.push("/");
            })
            .catch(error => {
                const { message, data } = error.response?.data || {};
                if (data?.cerrar_modal) {
                    setCodigo2FAModal({ visible: false });
                    setTemp2FAToken(null);
                }
                toast.current.show({ severity: 'error', summary: 'Error', detail: message || 'Código incorrecto.' });
            });
    };

    const verificar2FA = (codigo2fa) => {
        return authService.verificar2FA({ token_2fa: temp2FAToken, codigo_2fa: codigo2fa })
            .then(res => {
                saveToken(res.data.data);
                setCodigo2FAModal({ visible: false });
                setTemp2FAToken(null);
                if (getNombre()) history.push("/");
            })
            .catch(error => {
                const { message, data } = error.response?.data || {};
                if (data?.cerrar_modal) {
                    setCodigo2FAModal({ visible: false });
                    setTemp2FAToken(null);
                }
                toast.current.show({ severity: 'error', summary: 'Error', detail: message || 'Código incorrecto.' });
            });
    };

    const manejarCodigo2FA = (codigo2fa) => {
        if (codigo2FAModal.modo === "ENLACE") {
            return enlazar2FA(codigo2fa);
        } else {
            return verificar2FA(codigo2fa);
        }
    };

    return (
        <div className="login-body">
            <Toast ref={toast} />
            <div className="login-wrapper">
                <div className={`login-panel ${codigo2FAModal.visible ? "modal-active" : ""}`}>
                    <div className="login-logo">
                        <img src="assets/layout/images/logoPrelafit.png" className="logo" alt="Prelafit" />
                    </div>

                    <div className="login-form">
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <h2>Ingresar</h2>
                            <>
                                <Controller name="usuario" control={control} rules={{
                                    required: 'Nombre de usuario es requerido.', pattern: { value: /^[^\s*$]/, message: 'Nombre de usuario incorrecto' },
                                    maxLength: { value: 200, message: 'Máximo 100 caracteres para el usuario' }, minLength: { value: 4, message: 'Mínimo 4 caracteres para el usuario' }
                                }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} style={{ width: '100%' }} className={classNames({ 'p-invalid': fieldState.isDirty })} placeholder="Usuario" />
                                )} />
                                {getFormErrorMessage('usuario')}
                            </>
                            <br />
                            <br />

                            <>
                                <Controller name="clave" control={control} rules={{
                                    required: 'Clave es requerido.',
                                    maxLength: { value: 200, message: 'Máximo 200 caracteres para la clave' }, minLength: { value: 4, message: 'Mínimo 4 caracteres para la clave' }
                                }} render={({ field: { ref, ...field }, fieldState }) => (
                                    <Password id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.isDirty })} placeholder="Clave" />
                                )} />
                                {getFormErrorMessage('clave')}
                            </>
                            <br />
                            <>
                                <Messages ref={message} />
                                <Button label="CONTINUAR" type="submit" icon={loading ? "pi pi-spin pi-spinner" : ""} disabled={loading} />
                            </>

                        </form>
                    </div>

                    <p>Recordar contraseña <br /><Resetear toast={toast} /></p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">

                    </div>
                    <div className="image-footer">
                        <p>© {(new Date()).getFullYear()} Prelafit - Todos los derechos reservados.</p>
                    </div>
                </div>
            </div>
            <Codigo2FA visible={codigo2FAModal.visible} onHide={() => { setCodigo2FAModal({ visible: false, modo: "VERIFICACION", qrCodeUrl: null }); setTemp2FAToken(null); }}
                modo={codigo2FAModal.modo} qrCodeUrl={codigo2FAModal.qrCodeUrl} funcion={manejarCodigo2FA}
            />
        </div>
    );
}
