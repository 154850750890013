import { FilterMatchMode } from "primereact/api";

export class Roles {

    model = {
        id: {value: null, export: false},
        nombre: {header: 'Nombre', value: '', required: "Nombre del rol es requerido", filterPlaceholder: 'Buscar por nombre de rol.', maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', min: 4, max: 200, export: true, table: true, filter: true, matchMode: FilterMatchMode.CONTAINS},
        descripcion: {header: 'Descripción', value: '', table: true, required: false, maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 2 caracteres',
            min: 2, max: 300, export: true},
        estado: {header: 'Estado', value: {nombre: 'Activo', cat_id: true}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
        aprobar: {header: 'Aprobar', value: {nombre: 'SI', cat_id: true}, required: 'Regresar a borrador o finalizar formulario.', optionLabel: 'nombre'},
        registros: {header: 'Ver todo', value: {nombre: 'SI', cat_id: true}, required: 'Estado es requerido', optionLabel: 'nombre', export: true},
        mostrar_empresas: {header: 'Mostrar empresas', value: {nombre: 'NO', cat_id: false}, required: 'Mostrar listado de empresas (válido para Grupo empresarial).', optionLabel: 'nombre'},
        multiempresa: {header: 'Es multiempresa', value: {nombre: 'NO', cat_id: false}, required: 'Seleccione una opción', optionLabel: 'nombre'},
        icono: {header: 'Ícono', value: '', required: false, maxLength: 'Máximo 150 caracteres', minLength: 'Mínimo 1 caracter', min: 1, max: 150, export: false},
        fecha: {header: 'Fecha generación', value: new Date(), export: true, table: true},
        nemonico: {header: 'Nemónico', value: '', table: false, required: false, maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres',
            min: 4, max: 100, export: true},
    }
}
