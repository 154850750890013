import { DataTable } from "primereact/datatable"
import { useEffect, useRef, useState } from "react";
import AdjuntoService from "../service/AdjuntoService";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Adjunto } from "../models/Adjunto";
import { InputComponent } from "./InputComponent";
import { useForm } from "react-hook-form";
import { FileUpload } from "primereact/fileupload";
import { Card } from "primereact/card";
import { useToken } from "./useToken";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Generico } from "./Generico";

export const AnexosComponent = ({ generic, tipo, idp, urls, permss, toast, estado, reiniciar, setDialogSubir }) => {
    const omodel = new Adjunto();
    const { resetAriaHidden } = Generico();
    const model = omodel.model;
    const defaultValues = generic.default(model);
    const { handleSubmit, control, formState: { errors }, reset, setError } = useForm(defaultValues);
    const { inArea } = InputComponent({ omodel, generic, errors, control, permss, id: idp });
    const [registers, setRegisters] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const [adjunto, setAdjunto] = useState(null);
    const [load, setLoad] = useState(false);
    const { getTipo, getRol, getPerss } = useToken();
    let fileRef = useRef(null);
    const adjuntoSrv = new AdjuntoService();
    const [dialogAdjunto, setDialogAdjunto] = useState(false);
    const menu = useRef(null);
    const [carga, setCarga] = useState(false);

    const onPage = (event) => {
        event.filters = lazyState.filters;
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: parseInt(process.env.REACT_APP_ROWS_NUM),
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            nombre: { value: '', matchMode: 'contains' },
        }
    });

    useEffect(() => {
        loadLazyAdjunto();
    }, [lazyState]);

    const loadLazyAdjunto = () => {
        setLoading(true);
        const _lazyParams = lazyState;
        _lazyParams.idp = idp;
        _lazyParams.tipo = tipo;
        _lazyParams.rol = getRol()?.id;
        adjuntoSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, urls.urlListado).then((data) => {
            setTotalRecords(data.data.total);
            setRegisters(data.data.registros);
            setLoading(false);
        }).catch((error) => {
            setLoading(false); // Importante para quitar el bloqueo
            if (error.response && error.response.status === 404) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.message,
                    life: 3000
                });
            } else {
                // Manejar otros errores genéricos
                generic.funcError(error, toast);
            }
        });
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el adjunto?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteAdjunto(data)
        });
    }

    const deleteAdjunto = (data) => {
        adjuntoSrv.deleteAdjunto(data.ida, idp, urls.udelete).then(res => {
            loadLazyAdjunto();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast, setError);
        });
    }

    const editAdjunto = () => {
        setDialogAdjunto(true);
        setArchivo(null);
    }

    const descargar = (data) => {
        adjuntoSrv.descargar(data.ida, urls.urlDownload, idp).then(res => {
            generic.downloadPdf(res, data.adjunto);
        });
    }

    const generar = (data) => {
        setLoading(true);
        adjuntoSrv.generar(data.id, urls.urlGenerar, idp).then(res => {
            setLoading(false);
            generic.downloadPdf(res, "Consulta-BASE-DE-DATOS.pdf");
            loadLazyAdjunto();
        }).catch(error => {
            setLoading(false);
            generic.funcMessageBlobError('Error al descargar', error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se han detectado errores. Por favor, revise información ingresada y proceda a corregir', life: 3000 });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && adjunto?.actualizar === true && adjunto?.consulta === false && estado !== 'FINALIZADO' && (!getPerss() || (getPerss() && estado === 'BORRADOR'))) {
            // Verifica si se puede subir un archivo
            items.push({ label: 'Subir archivo', icon: 'pi pi-upload', command: () => { editAdjunto(adjunto) } });
        }
        // Opción para descargar archivos aprobados
        if (permss?.actualizar && adjunto?.actualizar === true && adjunto?.consulta === true && estado === 'FINALIZADO' && adjunto?.adjunto === null && adjunto?.carga !== 'Pendiente') {
            items.push({ label: 'Descargar archivo', icon: 'pi pi-database', command: () => { generar(adjunto) } });
        }
        // Opción para descargar archivos antes de aprobar
        if (permss?.consultar && adjunto?.consultar === true && adjunto?.carga === 'Cargado') {
            items.push({ label: 'Descargar archivo', icon: 'pi pi-download', command: () => { descargar(adjunto) } });
        }

        if (permss?.eliminar && adjunto?.eliminar === true && adjunto?.carga !== 'Pendiente' && ((getPerss() && estado === 'BORRADOR') || !getPerss()) && estado !== 'FINALIZADO') {
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete(adjunto) } });
        }

        return [{ label: 'Opciones', items: items }];
    }

    const acciones = (rowData) => {
        return (
            <>
                <Menu model={itemsMenu()} popup ref={menu} id="popup_menu_center" onShow={resetAriaHidden} />
                <Button icon="pi pi-caret-down" className="p-button-rounded p-button-success mr-2" title="Opciones/Acciones" aria-controls="popup_menu_center" aria-haspopup
                    onClick={(event) => { menu.current.toggle(event); setAdjunto(rowData) }} />
            </>
        );
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (file.size > size) {
            toast.current.show({
                severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) +
                    " Mb. El archivo actual tiene: " + (file.size / 1024000).toFixed(3) + " Mb.", life: 3000
            });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (e.files.length <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo permitido para el archivo es: ' + (size / 1024000).toFixed(2) + " Mb.", life: 3000 });
            return;
        }
        if (e.files[0].size > size) {
            toast.current.show({
                severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) +
                    " Mb. El archivo actual tiene: " + (e.files[0].size / 1024000).toFixed(3) + " Mb.", life: 3000
            });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const onSubmitFile = (data) => {
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo);
        formData.append('tipo', tipo);
        formData.append('descripcion', data.descripcion ?? '');
        formData.append('id', idp);
        formData.append('lista', adjunto?.id);
        setCarga(true);
        adjuntoSrv.newAdjunto(formData, urls.urlUpload).then(res => {
            loadLazyAdjunto();
            reset(defaultValues);
            setArchivo(null);
            setDialogAdjunto(false);
            setCarga(false);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            setCarga(false);
            generic.funcError(error, toast, setError);
        });
    }

    const cambiarFormulario = (urlc, valor) => {
        confirmDialog({
            message: '¿Está seguro del cambio?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => aprobar(urlc, valor)
        });
    }

    const aprobar = (valor) => {
        setLoad(true);
        let data = { id: idp, estado: estado, cambio: valor };
        if (getRol()?.tipo) {
            data.tipo = getRol().tipo;
        }
        data.tipoOpcion = tipo;
        adjuntoSrv.aprobar(data, urls.uaprobar).then(res => {
            setLoad(false);
            reiniciar();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            setLoad(false);
            generic.funcDeleteError(error, toast, setError);
        });
    }

    return (
        <>
            <Card>
                <DataTable value={registers} lazy filterDisplay="row" dataKey="id" paginator
                    first={lazyState.first} rows={parseInt(process.env.REACT_APP_ROWS_NUM)} totalRecords={totalRecords} onPage={onPage}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading}>
                    <Column field="nombre" header="Documento" filter filterPlaceholder="Buscar" />
                    <Column field="obligatorio" header="Obligatorio" />
                    <Column field="carga" header="Estado" />
                    <Column body={acciones} />
                </DataTable>
                <br />

                <div className="formgrid grid">
                    <div className="field col-12" style={{ textAlign: 'center' }}>
                        {getTipo() === 'todos' && estado === 'FINALIZADO' && <><Button label="Cambiar a aprobar" type="button" style={{ maxWidth: '170px' }} icon="pi pi-history"
                            className="p-button-warning" onClick={() => cambiarFormulario('ca')} />&nbsp;</>}
                        {(getTipo() === 'empresa' || getTipo() === 'todos') && estado === 'ENVIADO' && <><Button label="Aprobar" type="button" style={{ maxWidth: '150px' }}
                            icon="pi pi-check-square" className="p-button-warning" onClick={() => cambiarFormulario('a')} loading={load} />&nbsp;</>}
                        {getTipo() === 'empresa' && estado === 'ENVIADO' && <><Button label="Volver a borrador" type="button" icon={"pi pi-undo"} className="p-button-warning"
                            style={{ maxWidth: '170px' }} onClick={() => cambiarFormulario('b')} />&nbsp;</>}
                        <Button label="Cerrar" icon="pi pi-times" style={{ maxWidth: '100px' }} disabled={load} type="button" className="p-button-warning" onClick={() => setDialogSubir(false)} />
                    </div>
                </div>
            </Card>

            <Dialog visible={dialogAdjunto} header="Nuevo archivo" modal className="p-fluid modal-ssize" onHide={e => setDialogAdjunto(false)} style={{ width: '300px' }}>
                <form onSubmit={handleSubmit(onSubmitFile, onError)}>
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <label><b>Seleccione un anexo*</b></label><br />
                            <FileUpload name="file" accept=".pdf" maxFileSize={parseInt(process.env.REACT_APP_FILE_SIZE)} uploadHandler={invoiceUploadHandler} mode="basic"
                                customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />
                            {archivo?.name}
                        </div>
                        {inArea('descripcion', 3, 30, 12, { obl: '' })}
                    </div>

                    <div className="formgrid grid">
                        <div className="field col" style={{ textAlign: 'center' }}>
                            <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="submit" icon="pi pi-check" className="p-button-warning" loading={carga} />
                        </div>
                        <div className="field col">
                            <Button label="Cerrar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" loading={carga} onClick={() => setDialogAdjunto(false)} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
