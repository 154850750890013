import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { ClientePJService } from '../../../service/ClientePJService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { FileComponent } from '../../../components/FileComponent';
import { DeclaracionCpj } from '../../../models/cpj/DeclaracionCpj';
import { Generico } from '../../../components/Generico';
import { Dialog } from 'primereact/dialog';
import { AnexosComponent } from '../../../components/AnexosComponent';

export const Declaracion = ({ toast, nuevo, id, setDialogClientepj, permss, cancelar, setdialogIns, urls, estado, reiniciar }) => {

    const generic = new GenericComponent();
    const { funcMessageBlobError, downloadPdf } = Generico();
    const omodel = new DeclaracionCpj();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    const [clientepj] = useState([]);
    const clientepjSrv = new ClientePJService();
    const { inArea } = InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id });
    const { enviarFormulario } =
        FileComponent({ generic, tipo: 'clientePj', toast, unuevo: 'ficha/clientepj/store', udelete: 'ficha/clientepj/eliminar-anexo', permss, id });
    const [loadReport, setLoadReport] = useState(false);
    const [dialogAnexos, setDialogAnexos] = useState(false);


    useEffect(() => {
        if (!nuevo) {
            clientepjSrv.consultarClientepj(id, 'p8').then(res => {
                reset(generic.renewValues(res.clientepj, omodel.model));
            });
        }
    }, [false])

    const onSubmit = (data) => {
        data.paso = 'p8';
        data.id = id;
        clientepjSrv.editarClientepj(data).then(res => {
            setdialogIns(true);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const generarReporte = () => {
        setLoadReport(true);
        clientepjSrv.descargar(getValues('id')).then(res => {
            downloadPdf(res, getValues('id') + '-Formulario-cliente-PJ.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        setDialogAnexos(true);
    }

    const enviar = () => {
        enviarFormulario(id, 'ficha/clientepj/enviar', 'clientePj');
    }

    return (
        <>
            < form onSubmit={handleSubmit(onSubmit)}>
                <Panel header="PARTE D" className="panel-ficha">
                    <Panel header="D.1 DECLARACIÓN DE ORIGEN LÍCITO DE FONDOS" className="panel-ficha">
                        <div className="field col" style={{ textAlign: 'justify' }}>
                            {omodel.strDeclara}
                        </div>
                    </Panel>
                    <br />

                    <Panel header="D.2 AUTORIZACIÓN PARA TRATAMIENTO DE DATOS" className="panel-ficha">
                        <div className="field col" style={{ textAlign: 'justify' }}>
                            {omodel.strAutorizacionTratamientoDatos}
                        </div>
                    </Panel>
                    <br />

                    <Panel header="OBSERVACIONES" className="panel-ficha">
                        <div className="formgrid grid">
                            {inArea('observacion', 5, 9000, 12)}
                        </div>
                    </Panel>
                    <br />

                    <Panel header="ANEXOS" className="panel-ficha">
                        <Panel header="PERSONA JURIDICA NACIONAL" className="panel-ficha">
                            <div className="formgrid grid">
                                {omodel.strAnexo1}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="PERSONA JURIDICA NACIONAL" className="panel-ficha">
                            <div className="formgrid grid">
                                {omodel.strAnexo2}
                            </div>
                        </Panel>
                    </Panel>
                </Panel>
                <br />
                {generic.buttonsFormsReports(setDialogClientepj, generarReporte, subirReporte, enviar, loadReport, id != null, cancelar)}
            </form >
            <Dialog visible={dialogAnexos} header="Gestión de Anexos" modal className="p-fluid modal-ssize" onHide={() => setDialogAnexos(false)} style={{ width: '800px' }}>
                <AnexosComponent generic={generic} tipo="clientePj" urls={urls} idp={id} toast={toast} permss={permss} setDialogSubir={setDialogAnexos} reiniciar={reiniciar} estado={estado} />
            </Dialog>
        </>
    )
}
