import { ClientePn } from "../../models/ClientePn";
import { GenericComponent } from "../../components/GenericComponent";
import { useEffect, useRef, useState } from "react";
import { ClientePNService } from "../../service/ClientePNService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Generico } from "../../components/Generico";
import { addLocale } from "primereact/api";
import { Panel } from "primereact/panel";
import { DatosPn } from "./cpn/DatosPn";
import { TabPanel, TabView } from "primereact/tabview";
import { ActividadPn } from "./cpn/ActividadPn";
import { ConyuguePn } from "./cpn/ConyuguePn";
import { PepPn } from "./cpn/PepPn";
import { PartebPn } from "./cpn/PartebPn";
import { AmpliadaPn } from "./cpn/AmpliadaPn";
import { DeclaracionPn } from "./cpn/DeclaracionPn";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useToken } from "../../components/useToken";
import { FileComponent } from "../../components/FileComponent";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";
import { Dialog } from "primereact/dialog";
import { AnexosComponent } from "../../components/AnexosComponent";

export const ClientePerNa = () => {

    const generic = new GenericComponent();
    const omodel = new ClientePn();
    const { accionesTbl } = Generico();
    let loadLazyTimeout = null;
    const [clientespn, setClientespn] = useState([]);
    const clientepnSrv = new ClientePNService();
    const toast = useRef(null);
    const [dialogClientepn, setDialogClientepn] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [permss, setPermss] = useState(null);
    const { getPerss, getRol } = useToken();
    const [siCliente, setSiCliente] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [urls, setUrls] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const { anexoPendiente, dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePn', toast, permss, id: cliente?.id });
    const [fpendiente, setFpendiente] = useState(null);
    const [estados, setEstados] = useState(null);
    const filters = [fpendiente, estados];
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: clientespn, omodel, generic, permss, id: cliente?.id, filters });

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setUrls({
            urlListado: 'ficha/clientepn',
            urlDownload: 'ficha/clientepn/descarga',
            urlUpload: 'ficha/clientepn/store',
            udelete: 'ficha/clientepn/eliminar-anexo',
            uactual: 'ficha/clientepn/editar',
            uenviar: 'ficha/clientepn/enviar',
            uaprobar: 'ficha/clientepn/aprobar',
        });
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            clientepnSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, 'clientepn').then(res => {
                setTotalRecords(res.data.total);
                setClientespn(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                if (res.data.cats) {
                    setCatalogos(res.data.cats);
                    setFpendiente(res.data.cats.pendiente);
                    setEstados(res.data.cats.estados);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            clientepnSrv.newClientepn({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogClientepn(true);
                setdialogIns(true);
                setCliente(null);
            });
        }
        else {
            setDialogClientepn(true);
            setCliente(null);
            setdialogIns(true);
        }
    }

    const editFicha = (clientepn = null, desdeMenu = false) => {
        if (catalogos == null) {
            clientepnSrv.nuevo({ iniciar: 1 }, 'clientepn').then(res => {
                setCatalogos(res.data.cats || res.data);
                setDialogClientepn(true);
                setActiveIndex(0);
                setdialogIns(true);
                nuevoCliente({ id: res.data.codigo, nemonico: res.data.nemonico });
                if (!desdeMenu) {
                    setCliente(res.data.cliente ? { id: res.data.cliente, civil: res.data.civil, nemonico: res.data.nemonico } : null);
                } else {
                    setCliente(clientepn);
                }
            });
        } else {
            setDialogClientepn(true);
            setActiveIndex(0);
            setdialogIns(true);
            setCliente(clientepn);
        }
    };

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCliente()
        });
    }

    const deleteCliente = () => {
        clientepnSrv.eliminarClientepn(cliente?.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const nuevoCliente = (nuevo, editar = false) => {
        let _cliente = cliente ?? {};
        if (nuevo) {
            if (nuevo?.id !== undefined) _cliente.id = nuevo.id;
            if (nuevo?.civil !== undefined) _cliente.civil = nuevo.civil;
            if (nuevo?.nemonico !== undefined) _cliente.nemonico = nuevo.nemonico;
        }
        setCliente(_cliente);
    }

    const regresar = () => {
        setActiveIndex(0);
        setDialogClientepn(false);
        loadLazyData();
    }

    const handleNext = () => {
        if (activeIndex < 6) {
            const num = activeIndex === 1 && !cliente.civil ? 2 : 1;
            setActiveIndex(activeIndex + num);
        }
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            const num = activeIndex === 3 && !cliente.civil ? 2 : 1;
            setActiveIndex(activeIndex - num);
        }
    };

    const nextPrev = (btn = true) => {
        const btnNext = btn ? <Button label="Siguiente" type="submit" icon="pi pi-chevron-right" /> :
            <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => { handleNext(); }} />;
        return (
            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />
                </div>
                <div className="field col">
                    {btnNext}
                </div>
            </div>
        )
    }

    const mensajeError = (mensaje) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: mensaje, life: 3000 });
    };

    // Menu del formulario Cliente Persona Natural
    const itemsMenu = () => {
        const items = [];

        if (permss?.actualizar && (!cliente?.nemonico || cliente.nemonico !== 'FINALIZADO')) {
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha(cliente, true) } });
        }

        if (permss?.consultar) {
            items.push({
                label: 'Anexos', icon: 'pi pi-folder-open',
                command: () => {
                    if (!cliente?.siSituacion) {
                        mensajeError("Primero debe seleccionar y guardar la situación laboral.");
                        return;
                    }
                    setDialogSubir(true);
                }
            });
        }

        if (permss?.eliminar && (!cliente?.nemonico || cliente.nemonico !== 'FINALIZADO')) {
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });
        }

        return [{ label: 'Opciones', items: items }];
    };

    const verificacion = (data) => {
        loadLazyData();
    }

    const reiniciar = () => {
        if (!siCliente) {
            loadLazyData();
        }
        setDialogClientepn(false);
        setDialogSubir(false);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogClientepn && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(clientespn, omodel, 'Reporte_clientes', 'LISTADO CLIENTES PERSONA NATURAL')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action01: accionesTbl, reg: setCliente, items: itemsMenu() })}
                </Card></>
            }
            {dialogClientepn &&
                <Panel header="INFORMACIÓN DE PERSONAS NATURALES" style={{ maxWidth: '100%', margin: 'auto' }}>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Sección 1">
                            <DatosPn id={cliente?.id} siCliente={siCliente} next={handleNext} permss={permss} catalogos={catalogos} nuevoCliente={nuevoCliente} toast={toast} seccion="seccion1" />
                        </TabPanel>

                        <TabPanel header="Sección 2" disabled={cliente == null}>
                            <ActividadPn generic={generic} nextPrev={nextPrev} next={handleNext} permss={permss} id={cliente?.id} catalogos={catalogos} toast={toast} seccion="seccion2" />
                        </TabPanel>

                        <TabPanel header="Sección 3" disabled={cliente == null || !cliente?.civil}>
                            <ConyuguePn generic={generic} id={cliente?.id} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion3" />
                        </TabPanel>

                        <TabPanel header="Sección 4" disabled={cliente == null}>
                            <PepPn generic={generic} id={cliente?.id} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion4" />
                        </TabPanel>

                        <TabPanel header="Sección 5" disabled={cliente == null}>
                            <PartebPn generic={generic} id={cliente?.id} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion5" />
                        </TabPanel>

                        <TabPanel header="Sección 6" disabled={cliente == null}>
                            <AmpliadaPn id={cliente?.id} nextPrev={nextPrev} permss={permss} next={handleNext} toast={toast} tipo='clientePn' />
                        </TabPanel>

                        <TabPanel header="Sección 7" disabled={cliente == null}>
                            <DeclaracionPn id={cliente?.id} permss={permss} setDialogClientepn={setDialogClientepn} toast={toast} seccion='seccion7' cancelar={getPerss() == undefined} setdialogIns={setdialogIns} urls={urls} estado={cliente?.nemonico} reiniciar={reiniciar} />
                        </TabPanel>
                    </TabView>
                </Panel>
            }
            <br />
            {dialogClientepn && <Button label="Regresar" icon="pi pi-replay" onClick={regresar} />}
            {dialogInstruccion(omodel.titulo, omodel.mensaje)}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}
            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <AnexosComponent generic={generic} tipo="clientePn" urls={urls} idp={cliente?.id} toast={toast} permss={permss} setDialogSubir={setDialogSubir} reiniciar={reiniciar}
                    loadLazyData={loadLazyData} verificacion={verificacion} estado={cliente?.nemonico} />
            </Dialog>
        </>
    )

}
