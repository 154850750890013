import Axios from "axios";

export class UsuarioService {

    getUsuarios(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/lista?${queryParams}`).then(res => res.data);
    }

    newUsuario(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/nuevo`, data).then(res => res.data);
    }

    editarUsuario(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/editar`, data).then(res => res.data);
    }

    consultarUsuario(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarUsuario(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/eliminar/${id}`).then(res => res.data);
    }

    getRolUsuario(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/rol-usuario?${queryParams}`).then(res => res.data);
    }

    getUsuarioEmpresa(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-empresa/lista?${queryParams}`).then(res => res.data);
    }

    putPermission(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/rol-asignar`, data).then(res => res.data);
    }

    putEmpresa(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/empresa-asignar`, data).then(res => res.data);
    }

    habilitar(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/habilitar`, data).then(res => res.data);
    }

    cambioEnvio(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/usuario/enviar`, data).then(res => res.data);
    }
}
