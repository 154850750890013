import { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputOtp } from "primereact/inputotp";
import { Toast } from "primereact/toast";
import { QRCodeCanvas } from "qrcode.react";
import React from 'react';
import './estilo.css';

export const Codigo2FA = ({ visible, onHide, funcion, modo = "VERIFICACION", qrCodeUrl = null }) => {
    const [codigo2fa, setCodigo2fa] = useState("");
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };

    const handleConfirm = () => {
        if (codigo2fa.length !== 6) {
            showToast("warn", "Advertencia", "El código debe contener 6 dígitos.");
            return;
        }
        setLoading(true);
        funcion(codigo2fa)
            .finally(() => {
                setLoading(false);
                setCodigo2fa(""); // Se limpia el código en el finally
            });
    };

    // Define el header según el modo
    const headerContent = (
        <div style={{ textAlign: "center", fontSize: "1.2rem", fontWeight: "bold" }}>
            {modo === "VERIFICACION" ? "Autenticación de Dos Factores (2FA)" : "Vinculación de Dispositivo para Autenticación de Dos Factores"}
        </div>
    );

    // Define el footer con botones según el modo
    const footer = (
        <div className="p-d-flex p-jc-center" style={{ gap: "1rem" }}>
            <Button label={modo === "VERIFICACION" ? "Verificar" : "Confirmar vinculación"} style={{ fontSize: "1rem" }} icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"} onClick={handleConfirm} disabled={loading} />
            <Button label="Cancelar" style={{ fontSize: "1rem" }} icon="pi pi-times" className="p-button-secondary" onClick={onHide} />
        </div>
    );

    const customInput = ({ events, props }) => {
        const { unstyled, invalid, ...restProps } = props;
        return (
            <React.Fragment key={restProps.id}> {/* Key en el Fragment */}
                <input {...events} {...restProps} type="text" className="otp-input-personalizado" autoComplete="off" />
                {restProps.id === 2 && (
                    <div className="px-3">
                        <i className="pi pi-minus" />
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog header={headerContent} visible={visible} style={{ width: "30vw" }} footer={footer} onHide={onHide}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {modo === "ENLACE" ? (
                        <>
                            <div style={{ marginBottom: "1rem", fontSize: "1rem" }}>
                                Escanea el código QR con Microsoft o Google Authenticator y luego ingresa el código de 6 dígitos.
                            </div>
                            <div style={{ marginBottom: "1rem" }}>
                                {qrCodeUrl ? (
                                    <QRCodeCanvas value={qrCodeUrl} size={200} />
                                ) : (
                                    <div>Cargando código QR...</div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div style={{ marginBottom: "1rem", fontSize: "1rem" }}>
                            Ingrese el código de verificación de 6 dígitos generado en su aplicación de autenticación (por ejemplo, Microsoft o Google Authenticator).
                        </div>
                    )}
                    <InputOtp
                        value={codigo2fa}
                        onChange={(e) => setCodigo2fa(e.value)}
                        length={6}
                        inputTemplate={customInput}
                        style={{ gap: 0 }}
                        integerOnly
                    />
                </div>
            </Dialog>
        </>
    );
};