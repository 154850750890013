import { useForm } from "react-hook-form";
import { InfoClienteService } from "../../service/matriz/InfoClienteService";
import { useEffect, useRef, useState } from "react";
import { Generico } from "../../components/Generico";
import { MatrizComponent } from "../../components/MatrizComponent";
import { DetailedCellError, HyperFormula } from "hyperformula";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from 'primereact/progressspinner';

export const DetalleProveedor = ({ matrizId, permss, setDialogPerfil, toast, tipo, regresar }) => {
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({});
    const clienteSrv = new InfoClienteService();
    const [matriz, setMatriz] = useState(null);
    const hfInstanceRef = useRef(null);
    const [loadSave, setLoadSave] = useState(false);
    const { funcError, funcSuccess, funcMessageBlobError, downloadPdf } = Generico();
    const [loadReport, setLoadReport] = useState(false);
    const [color, setColor] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [lista, setLista] = useState(null);
    const [loadCambio, setLoadCambio] = useState(false);
    const { numericRound, resume, onError, buttonsMatriz, cabecera, pie, factor, infoColor, scolor,
        infoSenialCli1, infoSenialControl, infoSenialPro2, proSenial2, tablaAlertaPro } = MatrizComponent({ control, errors, permss, toast });
    const [loadingDatos, setLoadingDatos] = useState(true);

    useEffect(() => {
        hfInstanceRef.current = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' });
        hfInstanceRef.current.addSheet('Sheet1');
        verificarMatriz();
        return () => {
            hfInstanceRef.current.destroy();
        };
    }, [matrizId]);

    const verificarMatriz = () => {
        clienteSrv.informacion(matrizId, tipo).then(res => {
            const _info = res.data;
            const _cat = _info?.catalogos;
            const _values = {};
            setColor(_info?.color);
            setCatalogos(_cat);
            const formulas = [];
            const data = [];
            let index = 0;
            const lista = {
                'pro1-1-valor': _cat?.TIPO_ESTRUCTURA_LEGAL,
                'fcr7-7-valor': _cat?.OTRAS_SENIALES,
                'sen12-12-valor': _cat?.CONTROL,
                'sen13-13-valor': _cat?.CONTROL,
                'sen14-14-valor': _cat?.CONTROL,
            }
            Object.keys(_info).map((key) => {
                const siMatriz = key.includes('pro') || key.includes('fcr') || key.includes('ale') || key.includes('sen');
                if (siMatriz) {
                    const orden = parseInt(_info[key].orden);
                    const valor = orden === 17 ? (parseFloat(_info[key]?.valor) === 1 ? 'SI' : 'NO') : parseFloat(_info[key]?.valor);
                    data.push([valor, parseFloat(_info[key]?.puntaje), parseFloat(_info[key]?.ponderacion)]);
                    const ccol = [9, 10, 11, 15, 16, 18, 19, 20].includes(orden) ? 0 : 1;
                    if (_info[key]?.formula) formulas.push({ value: _info[key]?.formula, row: index, col: ccol });
                    if (_info[key]?.formula1) formulas.push({ value: _info[key]?.formula1, row: index, col: 2 });

                    if ([12, 13, 14].includes(orden)) _values[`${key}-${orden}-valor`] = parseInt(_info[key]?.valor);
                    else if (_info[key]?.nemonico) _values[`${key}-${orden}-valor`] = _info[key]?.catalogo;
                    else if (orden === 17) {
                        _values[`${key}-${orden}-valor`] = parseFloat(_info[key]?.valor) === 1 ? { valor: 'SI', nombre: 'SI' } : { valor: 'NO', nombre: 'NO' };
                    }
                    //Colores
                    if ([1, 2, 3, 4, 5, 6, 7, 9, 10, 18].includes(orden)) {
                        if (_info[key]?.bcolor) {
                            const color = { bcolor: _info[key]?.bcolor, tcolor: _info[key]?.tcolor };
                            _info[key].color = color;
                        }
                    }
                    //Info
                    if ([10, 11, 16, 19, 20].includes(orden)) _info[key].valor = _info[key].calculado1;
                    else if ([17].includes(orden)) _info[key].ponderacion = _info[key].calculado1;
                    delete _info[key]?.formula;
                    delete _info[key]?.formula1;
                    index++;
                }

                return 1;
            });
            Object.keys(lista).forEach(function (key) {
                if (_values[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _values[key]) {
                            _values[key] = element;
                        }
                    });
                }
            });

            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: 0, row: 0 },
                data
            );
            formulas.forEach((formula) => {
                hfInstanceRef.current.setCellContents(
                    { sheet: 0, col: formula.col, row: formula.row },
                    [[formula.value]]
                );
            });
            delete _info.color;
            delete _info.catalogos;
            //console.log(_info)
            setMatriz(_info);
            //console.log(_values)
            reset(_values);
        }).catch(error => {
            funcError(error, toast, setError);
        }).finally(() => setLoadingDatos(false));
    }

    const valueCell = (field, value, row) => {
        const cols = { valor: 0, puntaje: 1, ponderacion: 2, monto: 3 };
        if (value !== null && value !== undefined) {
            hfInstanceRef.current.setCellContents({ sheet: 0, col: cols[field], row: row }, [[value]]);
        }
        const calculatedValue = hfInstanceRef.current.getCellValue({ sheet: 0, col: cols[field], row: row });
        if (calculatedValue instanceof DetailedCellError) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${calculatedValue.type}, Message: ${calculatedValue.message}, field: ${field}, value: ${value}, row: ${row}`, life: 3000 });
            return null;
        }

        return numericRound(calculatedValue)
    }

    const calcular = (value, name) => {
        const [key, orden, field] = name.split('-');
        const rrow = parseInt(orden) - 1;
        let val = value?.valor ?? (value?.cat_id ?? 0);

        const _item = matriz[key];
        _item.valor = valueCell(field, val, rrow);
        _item.color = scolor(_item?.valor, color);
        _item.puntaje = valueCell('puntaje', null, rrow);

        setMatriz(prevItem => ({
            ...prevItem,
            [key]: _item
        }));
        const arrs = [6, 8, 9, 10, 11, 15, 16, 17, 18, 19, 20];
        for (let i = 0; i < arrs.length; i++) {
            let _tip = 'pro';
            if (arrs[i] === 8) _tip = 'ale';
            else if (arrs[i] >= 9) _tip = 'sen';
            const _cal = matriz[`${_tip}${arrs[i]}`];
            _cal.puntaje = valueCell('puntaje', null, arrs[i] - 1);
            _cal.ponderacion = valueCell('ponderacion', null, arrs[i] - 1);
            if ([9, 10, 11, 15, 16, 18, 19, 20].includes(arrs[i])) {
                _cal.valor = valueCell('valor', null, arrs[i] - 1);
            }

            if (arrs[i] === 8) _cal.color = scolor(matriz?.pro6?.puntaje, color);
            else if ([9, 15, 16, 18, 20].includes(arrs[i])) _cal.color = scolor(_cal?.valor, color);
            else if (arrs[i] === 10) _cal.color = scolor(matriz?.sen9?.valor, color);
            else if (arrs[i] === 11) _cal.color = scolor(matriz?.sen9?.valor, color);
            else if (arrs[i] === 19) _cal.color = scolor(matriz?.sen18?.valor, color);
            else _cal.color = scolor(_cal?.puntaje, color);

            if (arrs[i] === 8) {
                //console.log(matriz['pro6']?.valor)
                _cal.valor = valueCell('puntaje', null, 5);
            }
        }
    }

    const generarReporte = () => {
        setLoadReport(true);
        clienteSrv.descargar(matrizId, tipo).then(res => {
            downloadPdf(res, 'Matriz-proveedor.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Asegúrese de ingresar la información antes de generar el reporte.', error, toast);
        });
    }

    const onSubmit = (data) => {
        setLoadSave(true);
        const _matriz = matriz;
        _matriz.id = matrizId;
        _matriz.values = data;
        clienteSrv.detalle(_matriz, tipo).then(res => {
            setLoadSave(false);
            funcSuccess(res, toast);
        }).catch(error => {
            setLoadSave(false);
            funcError(error, toast, setError);
        });
    }

    const search = (event, inf) => {
        if (!event.query.trim().length) {
            setLista([...lista]);
        }
        else {
            const vals = inf.split("-");
            clienteSrv.getLista(event.query.toLowerCase(), vals[0], vals[1]).then(data => {
                setLista(data.data);
            });
        }
    }

    const finalizar = () => {
        setLoadCambio(true);
        clienteSrv.finalizar({ id: matrizId, estado: 'BORRADOR', cambio: 'a' }, tipo).then(res => {
            setLoadCambio(false);
            funcSuccess(res, toast);
            regresar();
        }).catch(error => {
            setLoadCambio(false);
            funcError(error, toast, setError);
        });
    }

    return loadingDatos ? (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", textAlign: "center", gap: "20px" }}>
            <h2 style={{ fontSize: "16px", fontWeight: "bold", textTransform: "uppercase", letterSpacing: "1px" }}>
                Cargando datos...
            </h2>
            <ProgressSpinner style={{ width: '70px', height: '70px' }} strokeWidth="6" fill="var(--surface-ground)" animationDuration="4s" />
        </div>
    ) : (
        <Panel header="Matriz" style={{ maxWidth: '800px', margin: 'auto' }} className="panel-ficha">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="grid grid-nogutter">
                    {resume(1, matriz?.empresa, 12, 1, true, '2xl')}
                    {resume(2, matriz?.nombre_plantilla, 12, 1, true, '2xl')}
                    {resume(3, `FECHA DE ANÁLISIS: ${matriz?.fecha}`, 12, 1, true, null, 'center', null, 'border-bottom-1')}
                    {resume(4, 'INFORMACIÓN DEL PROVEEDOR', 12, 0, true, '2xl', 'center', null, 'border-bottom-1')}
                    {resume(5, 'NOMBRE/RAZÓN SOCIAL:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {resume(6, matriz?.nombres, 7, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                    {resume(7, 'IDENTIFICACIÓN:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {resume(8, matriz?.identificacion, 5, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                </div>
                <br />

                <Panel header="PROVEEDOR" style={{ width: '100%', margin: 'auto' }}>
                    <div className="formgrid grid align-items-center justify-content-center">
                        {cabecera()}
                        {matriz && factor('pro1-1-valor', catalogos?.TIPO_ESTRUCTURA_LEGAL, matriz, calcular, 'drop')}
                        {matriz && factor('pro2-2-valor', lista, matriz, calcular, 'complete', search)}
                        {matriz && factor('pro3-3-valor', lista, matriz, calcular, 'complete', search)}
                        {matriz && factor('pro4-4-valor', lista, matriz, calcular, 'complete', search)}
                        {matriz && factor('pro5-5-valor', lista, matriz, calcular, 'complete', search)}
                        {matriz && pie('VALOR PROVEEDOR:', 'pro6', matriz)}
                    </div>
                </Panel>
                <br />

                <Panel header="FACTOR ACELERADOR" style={{ width: '100%', margin: 'auto' }}>
                    <div className="formgrid grid justify-content-center">
                        {matriz && factor('fcr7-7-valor', catalogos?.OTRAS_SENIALES, matriz, calcular, 'drop')}
                    </div>
                </Panel>
                <br />

                <Panel header="TABLA DE ALERTAS" style={{ width: '100%', margin: 'auto' }}>
                    {matriz && tablaAlertaPro(matriz)}
                </Panel>
                <hr />

                <Panel header="RESULTADOS" style={{ width: '100%', margin: 'auto' }}>
                    <div className="formgrid grid justify-content-center">
                        <div className="col-3">
                            <div className="formgrid grid justify-content-center">
                                {color && infoColor(color)}
                            </div>
                        </div>

                        <div className="col-9">
                            <div className="formgrid grid justify-content-center">
                                {matriz && infoSenialCli1(matriz, 9, 11)}
                                <div className="field col-4 m-0 text-center text-xs font-bold border-1">
                                    <div className="formgrid grid justify-content-center">
                                        {matriz && infoSenialControl(catalogos, calcular, false)}
                                    </div>
                                </div>

                                {matriz && infoSenialPro2(matriz, calcular)}
                                {matriz && proSenial2(matriz)}
                            </div>
                        </div>
                    </div>
                </Panel>

                <br />
                {buttonsMatriz({
                    permss, btn1: { setDlg: setDialogPerfil }, btn2: { loadSave }, btn3: { loadReport, chn: generarReporte },
                    btn4: { msg: <>Verifique que la matriz esté guardada (Imprimir).<br />¿Desea finalizar la matriz?</>, chn: finalizar, loadCambio }
                })}
            </form>
        </Panel>
    )
}
