import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { NombrePep } from "../../models/NombrePep";
import { useForm } from "react-hook-form";
import { PepService } from "../../service/PepService";
import { InputComponent } from "../../components/InputComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

export const Peps = () => {
    const generic = new GenericComponent();
    const omodel = new NombrePep();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const toast = useRef(null);
    const pepService = new PepService();
    const [dialogPep, setDialogPep] = useState(false);
    const [selectedIns, setSelectedIns] = useState(null);
    const [ins, setIns] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [dialogAdjunto, setDialogAdjunto] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const [carga, setCarga] = useState(false);
    let fileRef = useRef(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber, inAutocomplete } =
        InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            pepService.getPep({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                if (res.data.catalogos) {
                    setCatalogos(res.data.catalogos);
                }
            });
        });
    }

    const openNew = () => {
        setDialogPep(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            pepService.newPep(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPep(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            pepService.editarPep(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPep(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editarPep = (inst) => {
        pepService.consultarPep(inst.id).then(res => {
            let _inst = res.data;
            let lista = { estado: generic.estadosNum, tipo: catalogos?.tipo_identificacion };

            Object.keys(lista).forEach(function (key) {
                if (_inst[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _inst[key]) {
                            _inst[key] = element;
                        }
                    });
                }
            });
            setSelectedIns(_inst.institucion);
            reset(generic.renewValues(_inst, omodel.model));
            setDialogPep(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminar(data)
        });
    }

    const eliminar = (data) => {
        pepService.eliminarPep(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const searchIns = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setIns([...ins]);
            }
            else {
                pepService.getInstituciones(event.query.toLowerCase()).then(data => {
                    setIns(data.data);
                });
            }
        });
    }

    const nuevoPuesto = (data) => {
        reset(defaultValues);
        setValue('nombres', data.nombres);
        setValue('identificacion', data.identificacion);
        setValue('tipo', data.tipo);
        setDialogPep(true);
    }

    const onSubmitFile = (data) => {
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo);
        setCarga(true);
        pepService.procesar(formData).then(res => {
            setCarga(false);
            setArchivo(null);
            setDialogAdjunto(false);
            //generic.funcSuccess(res, toast);
            generic.downloadPdf(res.data, 'Resultados.xlsx');
        }).catch(error => {
            setCarga(false);
            generic.funcError(error, toast, setError);
        });
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (file.size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) + " Mb. El archivo actual tiene: " + (file.size / 1024000).toFixed(3) + " Mb.", life: 3000 });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (e.files.length <= 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo permitido para el archivo es: ' + (size / 1024000).toFixed(2) + " Mb.", life: 3000 });
            return;
        }
        if (e.files[0].size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) + " Mb. El archivo actual tiene: " + (e.files[0].size / 1024000).toFixed(3) + " Mb.", life: 3000 });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const nuevoProceso = () => {
        setDialogAdjunto(true);
        setArchivo(null);
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button label="Nuevo origen" icon="pi pi-plus" className="p-button-success mr-2" type="button" onClick={openNew} />
                {permss?.crear && <Button label="Procesar archivo" icon="pi pi-upload" className="p-button-success mr-2" type="button" onClick={nuevoProceso} />}
            </div>
        );
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: editarPep, perm: permss, methDelete: confirmDelete, details: nuevoPuesto, icono: 'pi pi-database' })}
            </Card>
            <Dialog visible={dialogPep} header={getValues('id') == null ? "Nuevo registro" : "Editar registro"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogPep(false)} style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombres', {}, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('puesto', {}, 6)}
                        {inNumber('rmu_mensual', 2, true, {}, 6)}
                        {inNumber('rmu_anual', 2, true, {}, 6)}
                        {inDrop('estado', generic.estadosNum, { lab: omodel.optionLabel }, 3)}
                        {inDrop('tipo', catalogos?.tipo_identificacion, { lab: omodel.optionLabel }, 3)}
                        {inAutocomplete('institucion', selectedIns, setSelectedIns, ins, searchIns, { lab: omodel.optionLabel, noEsActividad: true, col: 12 })}
                    </div>

                    {generic.buttonsForms(setDialogPep)}
                </form>
            </Dialog>

            <Dialog visible={dialogAdjunto} header="Nuevo archivo" modal className="p-fluid modal-ssize" onHide={e => setDialogAdjunto(false)} style={{ width: '300px' }}>
                <form onSubmit={handleSubmit(onSubmitFile)}>
                    <div className="formgrid grid">
                        <span style={{ fontSize: '10px' }} >
                            Observaciones:
                            <ul>
                                <li>La primera columna debe ser la identificación.</li>
                                <li>La segunda columna debe ser el nombre completo.</li>
                                <li>La primera fila debe ser el título de cada columna.</li>
                                <li>Solo se toma en cuenta la primera hoja.</li>
                            </ul>
                        </span>
                        <div className="field col-12">
                            <label><b>Seleccione un archivo*</b></label><br />
                            <FileUpload name="file" accept=".xlsx" maxFileSize={parseInt(process.env.REACT_APP_FILE_SIZE)} uploadHandler={invoiceUploadHandler} mode="basic"
                                customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />
                            {archivo?.name}
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col" style={{ textAlign: 'center' }}>
                            <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="submit" icon="pi pi-check" className="p-button-warning" loading={carga} />
                        </div>
                        <div className="field col">
                            <Button label="Cerrar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" loading={carga} onClick={() => setDialogAdjunto(false)} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
