export class DeclaracionCpj {
    model = {
        observacion: {
            value: '',
            export: true,
            header: 'Observacion:',
            //            required: 'Observacion es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        }
    }
    strDeclara = `Declaro bajo juramento que los valores entregados son lícitos y  no provienen de actividades de lavado de activos, financiamiento del terrorismo y otros delitos, son generados de actividades económicas legales y que la información registrada en el presente documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad por los daños que pudiere sufrir la Compañía a la que declaro esta información por la eventual falsedad o imprecisión de esta. Así como, autorizo expresamente a  realizar el análisis y las verificaciones que se consideren pertinentes a la información suministrada y aquella que obtenga la Compañía a la que declaro y en el caso de detectarse alguna inusualidad, informar de manera inmediata y documentada a las autoridades competentes.`;
    strAutorizacionTratamientoDatos = <>
        <ol style={{ paddingLeft: "13px", }}>
            <li>El Responsable de los Datos Personales ha puesto en mi conocimiento su Política de Tratamiento de Datos Personales, la misma que me ha sido entregada y que consta en su página web. En ella constan los principios, cuidados y derechos respecto al tratamiento de mis datos personales.</li>
            <br />
            <li>De conformidad con el art. 8 de la Ley Orgánica de Protección de Datos Personales y art. 5 de su Reglamento, con la suscripción del presente formulario, consiento de manera libre, previa, expresa, voluntaria, y debidamente informada al Responsable de los Datos Personales, para que realice el tratamiento de mis datos personales en calidad de responsable. Entiendo que mis datos personales podrán ser compartidos con terceros, en concordancia con las disposiciones legales.</li>
            <br />
            <li>El Responsable de los Datos Personales declara que el tratamiento de datos personales se realizará dentro de los límites y de conformidad y con fundamento en lo dispuesto en el Art. 66 nº 19 de la Constitución de la República, la Ley Orgánica de Prevención, Detección y Combate del Delito de Lavado de Activos y de la Financiación de Otros Delitos y la LOPDP. El Responsable tratará mis datos personales con reserva y ha adoptado las medidas técnicas y organizativas necesarias para garantizar su seguridad, integridad y confidencialidad.</li>
            <br />
            <li>Los datos y documentación que aporto mediante el presente formulario son veraces y exactos, y el Responsable podrá efectuar las validaciones que considere pertinentes.</li>
            <br />
            <li>Autorizo al Responsable de los Datos Personales a contactarme y enviarme comunicaciones por cualquier medio, teniendo en consideración la finalidad antes expresada. Asimismo, podré comunicarme con el Responsable en cualquier momento si tengo preguntas o solicitudes sobre el tratamiento de mis datos personales.</li>
        </ol>
    </>;
    strAnexo1 = <><ul><li>Copia del RUC de la compañía</li><li>Copia del nombramiento del Representante Legal debidamente inscrito</li>
        <li>Poder original de la persona que comparecerá en representación de la compañía, en lugar del Representante Legal</li>
        <li>Copia de cédula de los representantes legales o apoderados (copia de pasaporte en caso de ser extranjeros no residentes)</li>
        <li>Declaración de Impuesto a la Renta último año (Formulario 101)</li><li>Declaraciones de I.V.A. de los tres últimos meses</li>
        <li>Certificado bancario de cualquiera de los últimos tres meses.</li></ul></>;
    strAnexo2 = <><ul><li>Copia del documento de idenTificación tributaria de la compañía</li>
        <li>Copia del nombramiento del Representante Legal debidamente inscrito</li>
        <li>Poder original de la persona que comparecerá en representación de la compañía, en lugar del Representante Legal, debidamente apostillado, en idioma español y protocolizado</li>
        <li>Copia de cédula del representante legal o apoderado (copia del pasaporte en caso de ser extranjero no residente)</li>
        <li>Declaración de Impuesto a la Renta del último año y declaraciones de I.V.A de los tres últimos meses.</li>
        <li>Certificado bancario de cualquiera de los últimos tres meses.</li></ul></>;
}
