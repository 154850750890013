import Axios from "axios";

export class PepService {

    getPep(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/nombre-pep/lista?${queryParams}`).then(res => res.data);
    }

    newPep(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/nombre-pep/nuevo`, data).then(res => res.data);
    }

    editarPep(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/nombre-pep/editar`, data).then(res => res.data);
    }

    consultarPep(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/nombre-pep/mostrar/${id}`).then(res => res.data);
    }

    eliminarPep(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/nombre-pep/eliminar/${id}`).then(res => res.data);
    }

    getInstituciones(nombre, todo = 0) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/nombre-pep/institucion/${encodeURIComponent(nombre)}/${todo}`).then(res => res);
    }

    procesar(data) {
        const headers = { 'Content-Type': 'multipart/form-data', 'Pragma': 'no-cache' };
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/pep/nombre-pep/procesar`, data, { headers: headers, responseType: 'blob' }).then(res => res);
    }
}
