import { useEffect, useRef, useState } from "react";
import { InfoAccionistaService } from "../../service/matriz/InfoAccionistaService";
import { Generico } from "../../components/Generico";
import { MatrizComponent } from "../../components/MatrizComponent";
import { DetailedCellError, HyperFormula } from "hyperformula";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';

export const DetalleAccionistaUno = ({ matrizId, permss, setDialogPerfil, toast, edad, fecha, perfil, uno, regresarUno, regresar }) => {

    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({});
    const accionistaSrv = new InfoAccionistaService();
    const [rango, setRango] = useState(null);
    const [matriz, setMatriz] = useState(null);
    const hfInstanceRef = useRef(null);
    const [loadSave, setLoadSave] = useState(false);
    const { funcError, funcSuccess, funcMessageBlobError, downloadPdf, dateToString } = Generico();
    const [loadReport, setLoadReport] = useState(false);
    const [loadCambio, setLoadCambio] = useState(false);
    const { inDropAnio, inNumber2, numericRound, resume, generateRange, colorSenialDos, onError, buttonsMatriz } = MatrizComponent({ control, errors, permss, toast });
    const [loading, setLoading] = useState(false);
    const [loadingDatos, setLoadingDatos] = useState(true);

    useEffect(() => {
        if (edad) {
            setMatriz(prevItem => ({
                ...prevItem,
                'fecha_nacimiento': dateToString(fecha)
            }));
            setMatriz(prevItem => ({
                ...prevItem,
                'edad': edad
            }));
            calcular(edad, 'edad1-1-edad');
        }
    }, [edad]);

    useEffect(() => {
        hfInstanceRef.current = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' });
        hfInstanceRef.current.addSheet('Sheet1');
        generateRange(setRango);
        verificarMatriz();
        return () => {
            hfInstanceRef.current.destroy();
        };
    }, [matrizId]);

    const valueCell = (field, value, row) => {
        const cols = { valor_anio1: 0, valor_anio2: 1, anio1: 0, anio2: 1, anio3sel: 4, edad: 5 };
        if (value) {
            hfInstanceRef.current.setCellContents({ sheet: 0, col: cols[field], row: row }, [[value]]);
        }
        const calculatedValue = hfInstanceRef.current.getCellValue({ sheet: 0, col: cols[field], row: row });
        if (calculatedValue instanceof DetailedCellError) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${calculatedValue.type}, Message: ${calculatedValue.message}, field: ${field}, value: ${value}, row: ${row}`, life: 3000 });
            return null;
        }

        return numericRound(calculatedValue) + (field === 'calculado2' && typeof calculatedValue === 'number' ? ' %' : '');
    }

    const verificarMatriz = () => {
        accionistaSrv.informacion(matrizId, uno, perfil).then(res => {
            const _info = res.data;
            valueCell('anio3sel', _info?.anio3, 0);
            valueCell('edad', _info?.edad, 0);
            const _values = {};
            _values.anio3 = _info?.anio3 ? { nombre: _info?.anio3 + "", cat_id: _info?.anio3 } : null;
            const data = [];
            const formulas = [];
            let index = 0;
            Object.keys(_info).map((key) => {
                const siMatriz = key.includes('eco') || key.includes('pat') || key.includes('sep');
                if (siMatriz) {
                    const orden = _info[key].orden;
                    if (_info[key]?.color.includes('MATRIZ_COLOR_CELESTE')) {
                        _values[`${key}-${orden}-valor_anio1`] = parseInt(_info[key]?.valor_anio1);
                    }
                    data.push([_info[key]?.valor_anio1, _info[key]?.valor_anio2]);
                    if (_info[key]?.anio1) formulas.push({ value: _info[key]?.anio1, row: index, col: 0 });
                    if (_info[key]?.anio2) formulas.push({ value: _info[key]?.anio2, row: index, col: 1 });
                    delete _info[key]?.anio1;
                    delete _info[key]?.anio2;
                    index++;
                }
                return 1;
            });
            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: 0, row: 0 },
                data
            );
            formulas.forEach((formula) => {
                hfInstanceRef.current.setCellContents(
                    { sheet: 0, col: formula.col, row: formula.row },
                    [[formula.value]]
                );
            });
            setMatriz(_info);
            reset(_values);
        }).finally(() => setLoadingDatos(false));
    }

    const onChangeRango = (e, name) => {
        valueCell(`${name}sel`, e.value?.cat_id, 0);
        setMatriz(prevItem => ({
            ...prevItem,
            [name]: e.value?.cat_id
        }));
    };

    const calcular = (value, name) => {
        const siNumber = name.includes('eco') || name.includes('pat');
        if (siNumber) {
            const _matriz = matriz;
            delete _matriz.inicio;
            setMatriz(_matriz);
        }
        const [key, orden, field] = name.split('-');
        const rrow = parseInt(orden) - 1;
        let _item = null;
        if (key.includes('edad')) {
            _item = valueCell(field, value, rrow);
        } else {
            _item = matriz[key];
            _item[field] = valueCell(field, value, rrow);
        }

        setMatriz(prevItem => ({
            ...prevItem,
            [key]: _item
        }));

        const arrs = [3, 6, 7, 9, 10, 11, 14];
        let ftl = 'eco';
        arrs.forEach(itm => {
            if (itm === 14) ftl = 'pat';
            const _cal = matriz[`${ftl}${itm}`];
            _cal.valor_anio1 = valueCell('valor_anio1', null, itm - 1);
        });

        for (let i = 15; i <= 20; i++) {
            const _cams = ['valor_anio1'];
            if (i === 15 || i === 17) {
                _cams.push('valor_anio2');
            }
            const _cal = matriz[`sep${i}`];
            _cams.forEach(cl => {
                const res = valueCell(cl, null, i - 1);
                _cal[cl] = res;
                if (res === null) {
                    return;
                }
            });
        }
    }

    const texto = (opc) => {
        return opc === 1 ? <>&lt;1: POSIBLE QUIEBRA<br />
            &gt;=1 Y &lt;1,50: POSICIÓN DELICADA<br />
            =1,5: RATIO ÓPTIMO/EQUILIBRADO<br />
            &gt;1,5: BASTANTE LIQUIDEZ</>
            : <>
                &lt;40%: AHORROS<br />
                ENTRE 40% Y 60%: RATIO ÓPTIMO<br />
                &gt;60%: DEUDA EN EXCESO
            </>
    }

    const onSubmit = (data) => {
        if (matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede guardar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadSave(true);
        const _matriz = matriz;
        accionistaSrv.detalle(_matriz, uno, perfil).then(res => {
            setLoadSave(false);
            funcSuccess(res, toast);
        }).catch(error => {
            setLoadSave(false);
            funcError(error, toast, setError);
        });
    }

    const generarReporte = () => {
        if (matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede generar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadReport(true);
        accionistaSrv.descargar(matrizId, uno, perfil).then(res => {
            downloadPdf(res, 'Reporte-Matriz.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Asegúrese de ingresar la información antes de generar el reporte.', error, toast);
        });
    }

    const infoEconomica = () => {
        const info = [];
        for (let num = 1; num <= 11; num++) {
            const bg1 = matriz[`eco${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? 'yellow-100' : 'black-alpha-60';
            const bg2 = matriz[`eco${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? 'blue-100' : 'black-alpha-60';
            const tc = matriz[`eco${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? '' : 'text-white';
            info.push(resume((num * 100) + 1, '', 2, 0, false, 'xs', 'center', null, null));
            info.push(resume((num * 100) + 2, matriz[`eco${num}`].nombre, 6, 0, false, 'xs', 'center', bg1, `border-1 ${tc}`));
            if (matriz[`eco${num}`].color.includes('MATRIZ_COLOR_CELESTE')) {
                info.push(inNumber2(`eco${num}-${num}-valor_anio1`, 2, { chn: calcular, key: (num * 100) + 3 }));
            } else {
                info.push(resume((num * 100) + 3, matriz[`eco${num}`].valor_anio1, 2, 0, false, 'xs', 'center', bg2, `border-1 ${tc}`));
            }
            info.push(resume((num * 100) + 4, '', 2, 0, false, 'xs', 'center', null, null));
        }

        return info;
    }

    const infoPatrimonial = (ini = 12, fin = 14, fld = 'pat') => {
        const info = [];
        for (let num = ini; num <= fin; num++) {
            info.push(resume((num * 100) + 1, '', 4, 0, false, 'xs', 'center', null, null));
            info.push(resume((num * 100) + 2, matriz[`${fld}${num}`].nombre, 2, 0, false, 'xs', 'center', null, 'border-1'));
            if (matriz[`${fld}${num}`].color.includes('MATRIZ_COLOR_CELESTE')) {
                info.push(inNumber2(`${fld}${num}-${num}-valor_anio1`, 2, { chn: calcular, key: (num * 100) + 3 }));
            } else {
                info.push(resume((num * 100) + 3, matriz[`${fld}${num}`].valor_anio1, 2, 0, false, 'xs', 'center', 'yellow-100', `border-1`));
            }
            info.push(resume((num * 100) + 4, '', 4, 0, false, 'xs', 'center', null, null));
        }

        return info;
    }

    const infoSenial = () => {
        const info = [];
        for (let num = 15; num <= 20; num++) {
            info.push(resume((num * 100) + 1, '', 2, 0, false, 'xs', 'center', null, null));
            const ncol = num <= 17 ? 2 : 3;
            info.push(resume((num * 101) + 2, matriz[`sep${num}`].nombre, ncol, 0, true, 'xs', 'center', null, 'border-1'));
            const anio1 = num >= 18 ? colorSenialDos(matriz[`sep${num}`].valor_anio1) : matriz[`sep${num}`].valor_anio1;
            const acol = num <= 17 ? 1 : 3;
            info.push(resume((num * 102) + 3, matriz[`sep${num}`].valor_anio1, acol, 0, true, 'xs', 'center', anio1, `border-1`));
            if (num === 15 || num === 17) info.push(resume((num * 100) + 4, matriz[`sep${num}`].valor_anio2, 3, 0, true, 'xs', 'center', colorSenialDos(matriz[`sep${num}`].valor_anio2), `border-1`));
            const fcol = num === 16 ? 6 : 4;
            let sim = '';
            if (num === 15) sim = texto(1);
            if (num === 17) sim = texto(2);
            info.push(resume((num * 103) + 4, sim, fcol, 0, true, 'xs', 'center', null, sim === '' ? null : `border-1`));
        }

        return info;
    }

    const finalizar = () => {
        if (matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede finalizar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadCambio(true);
        accionistaSrv.finalizar({ id: matrizId, estado: 'BORRADOR', cambio: 'a' }, uno, perfil).then(res => {
            setLoadCambio(false);
            funcSuccess(res, toast);
            if (res.data === 'FINALIZADO_UNO') {
                regresar();
            } else {
                regresarUno(res.data);
            }
        }).catch(error => {
            setLoadCambio(false);
            funcMessageBlobError("Error", error, toast);
        });
    }

    const buscarInformacion = () => {
        const anio3 = matriz?.anio3;

        if (!anio3) {
            toast.current.show({ severity: 'info', summary: 'Información', detail: 'Seleccione el período antes de buscar datos financieros.', life: 3000 });
            return;
        }

        setLoading(true);
        accionistaSrv.informacion(matrizId, uno, perfil, anio3).then(res => {
            const _info = res.data;
            procesarDatos(_info);
            if (_info.detalleMensajes && Array.isArray(_info.detalleMensajes)) {
                _info.detalleMensajes.forEach(detalle => {
                    toast.current.show({
                        severity: 'success', summary: detalle.titulo, sticky: true,
                        content: (props) => (
                            <div className="flex flex-column p-3">
                                <div className="flex align-items-center gap-2">
                                    <span className="font-bold text-800 text-lg">{props.message.summary}</span>
                                </div>
                                <div className="text-800 mt-2">
                                    <p className="m-0"><strong>📅 Período solicitado:</strong> {detalle.anio_solicitado}</p>
                                    <p className="m-0"><strong>📊 Año de datos encontrado:</strong> {detalle.anio_encontrado}</p>
                                    <p className="m-0"><strong>🗓️ Fecha de análisis:</strong> {detalle.fecha_analisis}</p>
                                </div>
                                <div className="mt-3">
                                    <p className="text-base text-900 font-semibold">{detalle.mensaje}</p>
                                </div>
                            </div>
                        )
                    });
                });
            } else {
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: "Se han encontrado datos financieros", life: 4000 });
            }
            setLoading(false);
        }).catch(error => {
            const errorMessage = error.response?.data?.message || "No se pudo importar la información";
            toast.current.show({ severity: 'warn', summary: 'Sin datos', detail: errorMessage, life: 3000 });
            setLoading(false);
        });
    };

    const procesarDatos = (_info) => {
        valueCell('anio3sel', _info?.anio3, 0);
        valueCell('edad', _info?.edad, 0);
        const _values = {};
        _values.anio3 = _info?.anio3 ? { nombre: _info?.anio3 + "", cat_id: _info?.anio3 } : null;
        const data = [];
        const formulas = [];
        let index = 0;
        Object.keys(_info).map((key) => {
            const siMatriz = key.includes('eco') || key.includes('pat') || key.includes('sep');
            if (siMatriz) {
                const orden = _info[key].orden;
                if (_info[key]?.color.includes('MATRIZ_COLOR_CELESTE')) {
                    _values[`${key}-${orden}-valor_anio1`] = parseInt(_info[key]?.valor_anio1);
                }
                data.push([_info[key]?.valor_anio1, _info[key]?.valor_anio2]);
                if (_info[key]?.anio1) formulas.push({ value: _info[key]?.anio1, row: index, col: 0 });
                if (_info[key]?.anio2) formulas.push({ value: _info[key]?.anio2, row: index, col: 1 });
                delete _info[key]?.anio1;
                delete _info[key]?.anio2;
                index++;
            }
            return 1;
        });
        hfInstanceRef.current.setCellContents(
            { sheet: 0, col: 0, row: 0 },
            data
        );
        formulas.forEach((formula) => {
            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: formula.col, row: formula.row },
                [[formula.value]]
            );
        });
        // Actualizamos los valores calculados recorriendo la matriz
        const updatedMatriz = { ..._info };
        Object.keys(updatedMatriz).forEach((key) => {
            const siMatriz = key.includes('eco') || key.includes('pat') || key.includes('sep') || key.includes('ses');
            if (siMatriz) {
                for (let i = 15; i <= 20; i++) {
                    const _cams = ['valor_anio1'];
                    if (i === 15 || i === 17) {
                        _cams.push('valor_anio2');
                    }
                    const _cal = updatedMatriz[`sep${i}`];
                    _cams.forEach(cl => {
                        const res = valueCell(cl, null, i - 1);
                        _cal[cl] = res;
                        if (res === null) {
                            return;
                        }
                    });
                }
            }
        });
        setMatriz(updatedMatriz);
        reset(_values);
    };

    return (
        <>
            {loadingDatos ? (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "40vh", textAlign: "center", gap: "20px" }}>
                    <ProgressSpinner style={{ width: '70px', height: '70px' }} strokeWidth="6" fill="var(--surface-ground)" animationDuration="4s" />
                    <h2 style={{ fontSize: "12px", fontWeight: "bold", textTransform: "uppercase", letterSpacing: "1px" }}>
                        Cargando datos...
                    </h2>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        <div className="field col-4" style={{ marginTop: '1.85rem' }}>
                            <Button
                                label={loading ? "Buscando información...." : "Buscar datos financieros"}
                                icon={loading ? "pi pi-spinner pi-spin" : "pi pi-search-plus"}
                                className="p-button-rounded"
                                onClick={buscarInformacion}
                                type="button"
                                disabled={loading}
                            />
                        </div>
                        {inDropAnio('anio3', rango, { lab: 'nombre', chn: onChangeRango, header: 'Período' }, 2)}
                    </div>
                    <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                        {resume(1, '', 2, 0, false, 'xs', 'center', null, null)}
                        {resume(2, 'INFORMACIÓN ECONÓMICA DEL ' + (perfil === 'n' ? 'ACCIONISTA' : 'EMPLEADO'), 6, 0, true, 'xs', 'center', null, 'border-1')}
                        <div className="field col-2 m-0 border-1">
                            <div className="grid grid-nogutter">
                                {resume(3, 'PERÍODO', 12, 0, true, 'xs', 'center', null, 'border-1')}
                                {resume(4, matriz?.anio3, 12, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                            </div>
                        </div>
                        {resume(5, '', 2, 0, false, 'xs', 'center', null, null)}
                        {matriz && infoEconomica()}
                    </div>
                    <br />

                    <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                        {resume(6, '', 4, 0, false, 'xs', 'center', null, null)}
                        {resume(7, 'INFORMACIÓN PATRIMONIAL DECLARADA POR EL ' + (perfil === 'n' ? 'ACCIONISTA' : 'EMPLEADO'), 4, 0, true, 'xs', 'center', null, 'border-1')}
                        {resume(8, '', 4, 0, false, 'xs', 'center', null, null)}

                        {resume(9, '', 4, 0, false, 'xs', 'center', null, null)}
                        {resume(10, 'PERÍODO:', 1, 0, true, 'xs', 'center', null, 'border-1')}
                        {resume(11, matriz?.anio3, 3, 0, true, 'xs', 'center', null, 'border-1')}
                        {resume(12, '', 4, 0, false, 'xs', 'center', null, null)}
                        {matriz && infoPatrimonial()}
                    </div>
                    <br />

                    <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                        {resume(13, '', 2, 0, false, 'xs', 'center', null, null)}
                        {resume(14, 'SEÑALES DE ALERTA:', 6, 0, true, 'sm', 'center', null, 'h-2rem')}
                        {resume(15, '', 4, 0, false, 'xs', 'center', null, null)}
                        {matriz && infoSenial()}
                    </div>

                    <br />
                    {buttonsMatriz({
                        permss, btn1: { setDlg: setDialogPerfil }, btn2: { loadSave }, btn3: { loadReport, chn: generarReporte },
                        btn4: { msg: <><ul><li>Verifique que la matriz esté guardada (Imprimir).</li><li>Si todavía no ingresa la Matriz a Dos años, no podrá hacerlo luego.</li></ul>¿Desea finalizar la matriz?</>, chn: finalizar, loadCambio }
                    })}
                </form>
            )}
        </>
    )
}
