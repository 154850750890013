import { FilterMatchMode } from "primereact/api";

export class Proveedor {

    model = {
        id: {
            value: null, export: false, header: 'Código', table: true, filter: true, filterPlaceholder: 'Buscar por código', filterField: 'id', sort: true,
            matchMode: FilterMatchMode.EQUALS, showFilterMatchModes: false, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: { value: new Date(), export: false, header: 'Fecha (día/mes/año):', required: 'Fecha es requerido', table: true, sort: true, headerStyle: { width: '10%', minWidth: '10rem' } },
        ciudad: { value: null, export: true, header: 'Ciudad:', required: 'Ciudad es requerida' },
        pais: { value: null, export: true, header: 'País:', required: 'País es requerido' },
        provincia: { value: null, export: true, header: 'Provincia:', required: 'Provincia es requerido' },
        canton: { value: '', export: true, header: 'Ciudad', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4 },
        tipo_persona: { value: null, export: false, header: 'Tipo de persona:', required: 'Tipo de persona es requerido' },
        tipo_informacion: { value: null, export: false, header: 'Tipo de declaración:', required: 'Tipo de declaración es requerido' },
        tipo_identificacion: {
            value: null, export: false, filterPlaceholder: 'Buscar por tipo de identificación', required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación', table: false, filter: false, sort: true
        },
        identificacion: {
            value: '', export: false, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', table: true, filter: true, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', fielterField: 'identificacion',
            matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }
        },
        nombre_completo: {
            value: '', export: true, header: 'Nombres / Razón social', table: true, sort: true, headerStyle: { width: '20%', minWidth: '20rem' },
            filterPlaceholder: 'Buscar por nombres - apellidos / razón social', filter: true, filterField: 'nombre_completo', matchMode: FilterMatchMode.CONTAINS,
        },
        compania: {
            value: '', export: false, header: 'Compañía que contrata:', required: 'Compañía contratada es requerido',
            maxLength: 'Máximo 500 caracteres', minLength: 'Mínimo 4 caracteres', max: 500, min: 4, table: true
        },
        proyecto: { value: false, export: false, header: 'Agrupa para proyecto empresarial:', required: 'Agrupa para proyecto empresarial es requerido', table: true },
        nombres: {
            value: '', export: true, header: 'Nombres', required: 'Nombres es requerido', maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombres'
        },
        apellidos: {
            value: '', export: true, header: 'Apellidos', required: 'Apellidos es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'apellidos'
        },
        razon_social: {
            value: '', export: true, header: 'Razón Social', required: 'Razón Social es requerido', maxLength: 'Máximo 400 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 400, min: 4, filterField: 'razon_social'
        },
        actividad: { value: null, export: false, header: 'Actividad Económica:', required: 'Actividad Económica es requerido.' },
        fecha_inicio: {
            value: new Date(), export: false, header: 'Fecha de inicio de actividad económica (día/mes/año):', required: 'Fecha de incio de actividad económica es requerido',
            table: false, sort: true, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        web: {
            value: '', export: false, header: 'Página web o dirección electrónica de la empresa:', required: 'Página web o dirección electrónica de la empresa es requerido',
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'web'
        },
        INGR_MENSUAL: { value: 0, export: false, header: 'Ingreso mensual USD:', required: 'Ingreso mensual es requerido.' },
        INGR_ANUAL: { value: 0, export: false, header: 'Ingreso anual USD:', required: 'Ingreso anual es requerido.' },
        EGRE_MENSUAL: { value: 0, export: false, header: 'Egreso mensual USD:', required: 'Egreso mensual es requerido.' },
        EGRE_ANUAL: { value: 0, export: false, header: 'Egresos anuales USD:', required: 'Egresos anuales es requerido.' },
        detalle: {
            value: '', export: false, header: 'Detalle:', required: 'Detalle es requerido', maxLength: 'Máximo 2000 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 2000, min: 4, filterField: 'detalle'
        },
        politicas: {
            value: null, export: false, required: 'Por favor seleccione', table: false, filter: false, sort: true,
            header: 'Su entidad/negocio/actividad cuenta con políticas y procedimientos de prevención de lavado de activos, financiamiento del terrorismo y otros delitos?'
        },
        sancion: {
            value: null, export: false, required: 'Por favor seleccione', table: false, filter: false, sort: true,
            header: '¿Ha recibido su entidad/persona alguna sanción o multa, o tiene algún procedimiento legal por incumplimiento a las disposiciones de la Ley de Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos, o al Código Orgánico Integral Penal, o alguna norma conexa en la materia en los últimos doce meses?'
        },
        socio: {
            value: null, export: false, required: 'Por favor seleccione', table: false, filter: false, sort: true,
            header: 'Es usted, el Representante Legal o algún Socio/Accionista un PEP?'
        },
        nombre_pep: {
            value: '', export: false, header: 'Nombre del PEP:', required: 'Nombres es requerido', maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombres'
        },
        cargo_pep: {
            value: '', export: false, header: 'Cargo:', required: 'Cargo es requerido', maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'cargo', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'cargo'
        },
        institucion_pep: {
            value: '', export: false, header: 'Institución donde trabaja o trabajó:', required: 'Institución es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'cargo'
        },
        fecha_pep: { value: new Date(), export: false, header: 'Fecha del nombramiento de la designación (día/mes/año):', required: 'Fecha es requerido', table: false },
        fecha_no_pep: { value: null, export: false, header: 'Fecha en que dejó de ser PEP, de no estar en funciones (día/mes/año):', required: false, table: false },
        observacion: {
            value: '', export: false, header: 'Detalle:', required: 'Detalle es requerido', maxLength: 'Máximo 2000 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 2000, min: 4, filterField: 'detalle'
        },
        direccion_oficina: {
            value: '', export: false, header: 'Dirección oficina / negocio:', required: 'Dirección principal es requerido', maxLength: 'Máximo 500 caracteres', table: false, filter: false, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por dirección principal', fielterField: 'direccion_principal',
            matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }
        },
        direccion_ciudad: { value: null, export: false, header: 'Ciudad:', required: 'Ciudad es requerida' },
        direccion_canton: { value: '', export: false, header: 'Ciudad', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4 },
        direccion_pais: { value: null, export: false, header: 'País:', required: 'País es requerido' },
        direccion_provincia: { value: null, export: false, header: 'Provincia:', required: 'Provincia es requerido' },
        codigo_postal: {
            value: '', export: false, header: 'Apartado postal', required: 'Apartado postal es requerido', maxLength: 'Máximo 100 caracteres', table: false, filter: false, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por dirección principal', fielterField: 'direccion_principal',
            matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }
        },
        telefono: {
            value: '', export: false, header: 'Teléfono', required: 'Teléfono es requerido', maxLength: 'Máximo 100 caracteres', table: false, filter: false, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'telefono', filterPlaceholder: 'Buscar por teléfono principal', fielterField: 'telefono',
            matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }
        },
        estado_ficha: { value: null, export: false, header: 'Estado', required: 'Estado formulario es requerido', table: true, sort: false },
        empresa: { value: null, export: true, header: 'Empresa:', required: 'Empresa es requerido' },
    }
    optionLabel = 'nombre';
    strAnexos = <><ul><li>Copia del RUC u otro documento de identificación tributaria (en caso de ser extranjero).</li><li>Copia del Nombramiento de Representante Legal o Poder, en el caso que suscriba un Apoderado.</li>
        <li>Declaración de Impuesto a la Renta del Último Año.</li><li>Copia del documento de identidad
            del Propietario, Representante Legal y/o Apoderado.</li><li>Copia del Certificado Bancario de cualquiera de los últimos tres meses.</li></ul></>
    strPep = <><p style={{ textAlign: 'justify' }}><b>PEP</b>: Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicas destacados en el Ecuador o en el
        Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo
        de control respectivo.</p><p style={{ textAlign: 'justify' }}><b>FAMILIARES O RELACIONADOS</b>: Son las relaciones comerciales o contractuales que involucren al cónyuge o a las personas unidas bajo el régimen legal de unión
            de hecho reconocida legalmente; o a familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad; y, el o los colaborador (es) directo (s) esto es la (s)
            persona (s) natural (es) con las cuales una Persona Expuesta Políticamente (PEP). se encuentre asociada o vinculada societariamente, o mantenga otro tipo de relaciones empresariales,
            comerciales o laborales estrechas, serán consideradas como PEP.</p></>;
    strDeclaracion = <>Declaro bajo juramento que la información registrada en el presente documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad por los
        daños que pudiere sufrir la Compañía a la que declaro esta información por la eventual falsedad o imprecisión de la misma. Así como, autorizo expresamente a  realizar el análisis y las
        verificaciones que se consideren pertinentes a la información suministrada y aquella que obtenga la compañía a la que declaro y en el caso de detectarse alguna inusualidad, informar de
        manera inmediata y documentada a las autoridades competentes. Así como, declaro que los bienes entregados a la compañía por su adquisión tienen un origen lícito y  no provienen de
        actividades de lavado de activos, financiamiento del terrorismo y otros delitos; y, que los valores receptados por la venta de los bienes/servicios serán utilizados para fines legales.</>;
    strAutorizacionTratamientoDatos = <>
        <ol style={{ paddingLeft: "13px", }}>
            <li>El Responsable de los Datos Personales ha puesto en mi conocimiento su Política de Tratamiento de Datos Personales, la misma que me ha sido entregada y que consta en su página web. En ella constan los principios, cuidados y derechos respecto al tratamiento de mis datos personales.</li>
            <br/>
            <li>De conformidad con el art. 8 de la Ley Orgánica de Protección de Datos Personales y art. 5 de su Reglamento, con la suscripción del presente formulario, consiento de manera libre, previa, expresa, voluntaria, y debidamente informada al Responsable de los Datos Personales, para que realice el tratamiento de mis datos personales en calidad de responsable. Entiendo que mis datos personales podrán ser compartidos con terceros, en concordancia con las disposiciones legales.</li>
            <br/>
            <li>El Responsable de los Datos Personales declara que el tratamiento de datos personales se realizará dentro de los límites y de conformidad y con fundamento en lo dispuesto en el Art. 66 nº 19 de la Constitución de la República, la Ley Orgánica de Prevención, Detección y Combate del Delito de Lavado de Activos y de la Financiación de Otros Delitos y la LOPDP. El Responsable tratará mis datos personales con reserva y ha adoptado las medidas técnicas y organizativas necesarias para garantizar su seguridad, integridad y confidencialidad.</li>
            <br/>
            <li>Los datos y documentación que aporto mediante el presente formulario son veraces y exactos, y el Responsable podrá efectuar las validaciones que considere pertinentes.</li>
            <br/>
            <li>Autorizo al Responsable de los Datos Personales a contactarme y enviarme comunicaciones por cualquier medio, teniendo en consideración la finalidad antes expresada. Asimismo, podré comunicarme con el Responsable en cualquier momento si tengo preguntas o solicitudes sobre el tratamiento de mis datos personales.</li>
        </ol>
    </>;
    strEntrega = `Declaro el origen lícito de mis recursos por cuanto los valores entregados son generados de mis actividades económicas legales y no provienen del lavado de activos, financiamiento del terrorismo y otros delitos.`;
    strRecepcion = `Declaro el origen lícito de los recursos por cuanto los valores recibidos y que constan registrados en este documento, serán utilizados en actividades económicas legales y permitidas por las leyes de la República del Ecuador.`;
    titulo = 'Instrucciones';
    mensaje = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>En cuanto se registre la información de las secciones de la 1 a la 7, favor guardar la información.</li>
        <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá imprimir y firmar físicamente o electrónicamente.</li>
        <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>
}
