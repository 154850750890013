import { GenericComponent } from '../../components/GenericComponent';
import { Usuarios } from '../../models/Usuarios';
import { useForm } from 'react-hook-form';
import { UsuarioService } from '../../service/UsuarioService';
import { useEffect, useRef, useState } from 'react';
import { InputComponent } from '../../components/InputComponent';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { RolUsuario } from './RolUsuario';
import { AsignarPermiso } from './AsignarPermiso';
import { Generico } from '../../components/Generico';
import { useToken } from '../../components/useToken';
import { EmpresaUsuario } from './EmpresaUsuario';

export const Usuario = () => {

    const generic = new GenericComponent();
    const omodel = new Usuarios();
    const { accionesTbl } = Generico();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const { getRol } = useToken();
    const [usuarios, setUsuarios] = useState(null);
    const usuarioService = new UsuarioService();
    const toast = useRef(null);
    const [dialogUsuario, setDialogUsuario] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [rolDialog, setRolDialog] = useState(false);
    const [load, setLoad] = useState(false);
    const [dialogPermisos, setDialogPermisos] = useState(false);
    const [opcion, setOpcion] = useState(null);
    const [dialogEmpresaUsuario, setDialogEmpresaUsuario] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inSwitch, inText } = InputComponent({ registers: usuarios, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
            _lazyParams.rol = getRol()?.id;
        }
        usuarioService.getUsuarios({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setUsuarios(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
                setOpcion(res.data.opcion);
            }
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            usuarioService.newUsuario({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogUsuario(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogUsuario(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            setLoad(true);
            usuarioService.newUsuario(data).then(res => {
                setLoad(false);
                generic.funcSuccess(res, toast);
                setDialogUsuario(false);
                loadLazyData();
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            usuarioService.editarUsuario(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogUsuario(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editUsuario = () => {
        const iniciar = catalogos == null ? 1 : null;
        usuarioService.consultarUsuario(usuario?.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar === 1) {
                cats = res.data.lista;
                setCatalogos(res.data.lista);
            }
            let _user = res.data.usuario;
            const lista = { empresa: cats.lista };

            Object.keys(lista).forEach(function (key) {
                if (_user[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _user[key]) {
                            _user[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_user, omodel.model));
            setDialogUsuario(true);
        });
    };

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la empresa?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarUsuario()
        });
    }

    const eliminarUsuario = () => {
        usuarioService.eliminarUsuario(usuario.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const asignarRol = (rol = true) => {
        setRolDialog(rol);
        setDialogPermisos(!rol);
    }

    const habilitar = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la sesión del usuario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => habilitarUsuario()
        });
    }

    const habilitarUsuario = () => {
        usuarioService.habilitar({ usuario: usuario?.id }).then(res => {
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const cambioEnvio = () => {
        confirmDialog({
            message: '¿Está seguro de cambiar la contraseña y enviar al correo ' + usuario?.email + '?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => cambioEnvioUsuario()
        });
    }

    const cambioEnvioUsuario = () => {
        setLoading(true);
        usuarioService.cambioEnvio({ ids: usuario?.id }).then(res => {
            generic.funcSuccess(res, toast);
            setLoading(false);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
            setLoading(false);
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (opcion && opcion !== 'sin') {
            if (permss?.actualizar) items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editUsuario() } });
            if (permss?.actualizar && opcion === 'PRELAFIT') items.push({ label: 'Asignar rol', icon: 'pi pi-key', command: () => { asignarRol() } });
            if (permss?.actualizar) items.push({ label: 'Habilitar sesión', icon: 'pi pi-check-circle', command: () => { habilitar() } });
            if (permss?.actualizar) items.push({ label: 'Resetear clave y enviar al correo', icon: 'pi pi-envelope', command: () => { cambioEnvio() } });
            if (permss?.actualizar && opcion === 'PRELAFIT') items.push({ label: 'Otros permisos', icon: 'pi pi-envelope', command: () => { asignarRol(false) } });
            if (permss?.actualizar && opcion === 'PRELAFIT') items.push({ label: 'Asignar Empresas', icon: 'pi pi-building-columns', command: () => { setDialogEmpresaUsuario(true) } });
            if (permss?.eliminar && opcion === 'PRELAFIT') items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });
        }

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo usuario', permss)}
                    end={generic.rightToolbarTemplate(usuarios, omodel, 'Listado_usuarios', 'LISTADO DE USUARIOS')}></Toolbar>
                {table({ action01: accionesTbl, reg: setUsuario, items: itemsMenu() })}
            </Card>

            <Dialog visible={dialogUsuario} header={getValues('id') == null ? "Nuevo usuario" : "Editar usuario"} modal className="p-fluid modal-ssize"
                style={{ maxWidth: '700px' }} onHide={e => setDialogUsuario(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('login', {}, 6)}
                        {inText('email', {}, 6)}
                        {inSwitch('estado', { labelTrue: 'SI', labelFalse: 'NO' }, 6)}
                        {inSwitch('google_2fa_enabled', { labelTrue: 'SI', labelFalse: 'NO' }, 6)}
                        {inDrop('empresa', catalogos?.lista, { lab: omodel.optionLabel }, 6)}
                        {!getValues('id') && inDrop('enviar', generic.sino, { lab: omodel.optionLabel }, 6)}
                    </div>
                    {generic.buttonsForms(setDialogUsuario, load)}
                </form>
            </Dialog>

            <Dialog visible={rolDialog} header={"Roles del usuario " + usuario?.nombre} modal className="p-fluid modal-ssize" onHide={e => setRolDialog(false)}>
                <RolUsuario id={usuario?.id} toast={toast} generic={generic} permss={permss} />
            </Dialog>

            <Dialog visible={dialogPermisos} header={"Otros permisos usuario: " + usuario?.nombre} modal className="p-fluid modal-ssize" onHide={e => setDialogPermisos(false)}
                style={{ maxWidth: '900px' }}>
                <AsignarPermiso usuario={usuario?.id} toast={toast} generic={generic} />
            </Dialog>

            <Dialog visible={dialogEmpresaUsuario} header={"Asignar empresas a usuario: " + usuario?.nombre} modal className="p-fluid modal-ssize" onHide={e => setDialogEmpresaUsuario(false)}
                style={{ maxWidth: '900px' }}>
                <EmpresaUsuario id={usuario?.id} toast={toast} generic={generic} permss={permss} />
            </Dialog>
        </>
    )
}
