import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import React from 'react'
import { Controller } from 'react-hook-form';
import { Generico } from './Generico';
import { AutoComplete } from 'primereact/autocomplete';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';

export const MatrizComponent = ({ control, errors, permss, toast }) => {
    const { getFormErrorMessage, currentDate, dateFormat, handleKeyDown } = Generico();

    const noEdit = () => {
        return !permss?.actualizar || !permss?.crear;
    }

    const inDropAnio = (field, items, opt = {}, col = 3) => {
        const obl = opt.obl ? "" : "*";

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}><span className="font-bold text-sm">{opt.header}{obl}</span></label><br />
                <Controller name={field} control={control} rules={{ required: 'Requerido' }} render={({ field, fieldState }) => (
                    <Dropdown id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value ?? null); opt.chn(e, field.name); }}
                        optionLabel={opt.lab} placeholder="Seleccione" filter showClear filterBy="nombre" className={classNames({ 'p-invalid': fieldState.error })}
                        style={{ width: '100%' }} disabled={noEdit()} options={items} panelStyle={{ width: '50px', fontSize: '11px' }} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inDropDown = (field, items, col = 3, op = {}) => {
        const obl = op.obl ? "" : "*";
        const ml = op.ml ? op.ml : '';
        const req = op.req ? op.req : 'Seleccione';
        const input = op.input && op.input === true ? 'border-0 border-blue-100 rounded-md w-full bg-blue-100 text-xs' : '';//h-full
        const key = op.key ?? field;

        return (
            <div className={"field col-" + col + ml} key={key}>
                {op.hd + obl !== '' && <><label htmlFor={field} className={classNames({ 'p-error': errors[field] })}><b className="text-sm">{op.hd}{obl}</b></label><br /></>}
                <Controller name={field} control={control} rules={{ required: req }} render={({ field, fieldState }) => (
                    <Dropdown id={field.name} value={field.value} autoFocus style={{ width: '100%' }} disabled={noEdit()}
                        onChange={(e) => { field.onChange(e.value === undefined ? null : e.value); if (op.chn) { if (op.val) { op.chn(e.value, op.val); } else { op.chn(e); } } }} options={items}
                        optionLabel={op.lab} placeholder="Seleccione" filter showClear filterBy={op.lab} className={classNames(input, { 'p-invalid': fieldState.error })} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inTextArea = (field, op = {}) => {
        const req = op.req ?? 'Campo obligatorio';
        const key = op.key ?? field;

        return (
            <div className={"field p-0 m-0 col-" + op.col} key={key}>
                {op.hd && <><label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{op.hd}</label><br /></>}
                <Controller name={field} control={control} rules={{ required: req }} render={({ field, fieldState }) => (
                    <InputTextarea rows={op.rows} cols={op.cols} id={field.name} onChange={(e) => { field.onChange(e.target.value); if (op.chn) { op.chn(e.target.value, field.name) } }}
                        className={classNames('w-full p-0 m-0', { 'p-invalid': fieldState.error })} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inNumber2 = (field, col = 3, opc = {}) => {
        const rules = { required: 'Requerido', minLength: { value: 0, message: 'Mínimo requerido es 0' } };
        const div = opc.div ?? "border-1 bg-blue-100";
        const input = opc.input ?? 'border-0 border-blue-100 rounded-md w-full bg-blue-100 text-xs';
        let disabled = noEdit();
        if (!noEdit()) {
            disabled = opc.disabled ? opc.disabled : false;
        }

        return (
            <div className={`field col-${col} m-0 ${div}`} style={{ display: 'flex', textAlign: 'center' }} key={opc.key}>
                <Controller name={field} control={control} rules={rules} render={({ field, fieldState }) => (
                    <InputNumber id={field.name} value={field.value} onChange={(e) => { field.onChange(e.value); opc.chn(e.value, field.name) }} allowEmpty={false}
                        inputClassName={input} min="0" maxFractionDigits="2" className={classNames({ 'p-invalid': fieldState.error })} mode="decimal" disabled={disabled}
                        useGrouping={false} key={opc.key + 'n'} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inNumber3 = (field, col = 3, opc = {}) => {
        const rules = { required: 'Requerido', minLength: { value: 0, message: 'Mínimo requerido es 0' } };
        let disabled = noEdit();
        if (!noEdit()) {
            disabled = opc.disabled ? opc.disabled : false;
        }

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}><b>{opc.hd}*</b></label><br />
                <Controller name={field} control={control} rules={rules} render={({ field, fieldState }) => (
                    <InputNumber id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value); if (opc.chn) { opc.chn(e.value, field.name) } }}
                        min="0" maxFractionDigits="2" allowEmpty={false} onKeyDown={handleKeyDown} useGrouping={false} locale="en-US"
                        className={classNames({ 'p-invalid': fieldState.error })} mode="decimal" disabled={disabled} style={{ width: '100%' }} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const buttonsMatriz = (opt = {}) => {
        const ps = opt?.permss;
        return (
            <div className="formgrid grid">
                <div className="field col-12" style={{ textAlign: 'center' }}>
                    {opt.btn1.setDlg && <Button label="Regresar" tooltip="Regresar" icon="pi pi-arrow-left" className="p-button-success mr-2" type="button" onClick={() => opt.btn1.setDlg(false)} />}
                    {ps?.crear && <Button label="Guardar" type="submit" icon="pi pi-save" tooltip="Guardar información" className="p-button-warning"
                        loading={opt.btn2.loadSave} disabled={opt.btn2.loadSave} />}&nbsp;&nbsp;
                    {/* {ps?.consultar && <Button label="Imprimir" icon="pi pi-print" type="button" tooltip="Generar matriz en PDF" className="p-button-primary"
                        onClick={() => opt.btn3.chn()} loading={opt.btn3.loadReport} disabled={opt.btn3.loadReport} />}&nbsp;&nbsp; */}
                    {ps?.actualizar && <Button label="Finalizar" icon="pi pi-check" type="button" tooltip='Finalizar matriz' className="p-button-info"
                        onClick={() => ejecucion(opt.btn4.msg, opt.btn4.chn)} loading={opt.btn4.loadCambio} disabled={opt.btn4.loadReport} />}
                </div>
            </div>
        )
    }

    const ejecucion = (mensaje, finalizar) => {
        confirmDialog({
            message: mensaje,
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => finalizar(),
        });
    }

    const numericRound = (value) => {
        const ttype = typeof value === 'number' && !isNaN(value);
        return ttype ? Math.round(value * 100) / 100 : value;
    };

    const resume = (key, label, col, margin, bold, zize, center = 'center', bgcolor = null, stl = null) => {
        const lstyle = {};
        if (bgcolor && bgcolor.includes('#')) {
            lstyle.backgroundColor = bgcolor;
            bgcolor = null;
        }
        if (zize && zize.includes('px')) {
            lstyle.fontSize = zize;
            zize = null;
        }
        let sstyle = stl ? ` ${stl} ` : '';
        sstyle += bold ? " font-bold " : "";
        sstyle += bgcolor ? ` bg-${bgcolor} ` : "";
        sstyle += zize ? ` text-${zize} ` : "";

        return (
            <div className={`field col-${col} m-${margin} ${sstyle} text-${center}`} style={lstyle} key={key}>{label}</div>
        );
    }

    const generateRange = (setRango, start = 2016, end = 2100) => {
        const range = [];
        for (let i = start; i <= end; i++) {
            range.push({ nombre: i + "", cat_id: i });
        }
        setRango(range);
    };

    const colorSenialDos = (value) => {
        let color = null;
        if (value && value.includes('REVISI')) {
            color = 'yellow-500';
        } else if (value && value.includes('GENERA')) {
            color = '#92d050';
        }
        return color;
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se han detectado errores. Por favor, revise la matriz y proceda a corregir', life: 3000 });
    }

    const yearRanges = (opcion) => {
        const yyear = (new Date()).getFullYear();
        let rng = '1900:' + yyear;
        if (opcion === 'hoyDespues') {
            rng = yyear + ":2100";
        } else if (opcion.includes(':')) {
            rng = opcion
        }

        return rng;
    }

    const inCalendarOne = (field, option = {}, col = 3) => {
        let maxDate = currentDate;
        let minDate = null;
        if (option.min) {
            minDate = option.minNull ? null : currentDate;
            maxDate = null;
        }
        if (option.onChange) {

        }
        let yr = yearRanges('');
        if (option.yr) {
            yr = yearRanges(option.yr);
        }
        let rules = { required: option.required };

        return (
            <div className={`field col-${col} m-0 border-1 bg-${option.bg}`}>
                <Controller name={field} control={control} rules={rules} render={({ field, fieldState }) => (
                    <Calendar id={field.name} value={field.value} onChange={(e) => { field.onChange(e.value); if (option.onChange) { option.onChange(e.value); } }}
                        maxDate={maxDate} minDate={minDate} dateFormat={dateFormat} showIcon disabled={noEdit()} inputClassName='w-full'
                        className={classNames({ 'p-invalid': fieldState.error })} monthNavigator={true} yearNavigator={true} yearRange={yr} readOnlyInput />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inComplete = (field, suggs, search, op = {}) => {
        const col = op.col ?? 3;
        const obl = op.obl ? "" : "*";
        const req = op.req ? op.req : 'Ingrese el texto a buscar y seleccione de la lista presentada.';

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}><b className="text-xs">{op.hd}{obl}</b></label><br />
                <Controller name={field} control={control} rules={{ required: req }} render={({ field, fieldState }) => (
                    <AutoComplete id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value); if (op?.chn) { op.chn(e.value, field.name) } }} suggestions={suggs}
                        completeMethod={e => { if (op.val) { search(e, op.val) } else { search(e) } }} field={op.lab} dropdownAriaLabel="Seleccione" className={classNames({ 'p-invalid': fieldState.error })}
                        tooltipOptions={{ position: 'top' }} inputStyle={{ width: '100%' }} style={{ width: '100%' }} disabled={noEdit()} dropdown dropdownIcon="pi pi-search"
                        placeholder='Ingrese el texto a buscar y seleccione de la lista presentada.' tooltip={field.value?.nombre_completo} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const cabecera = () => {
        return (
            <>
                <div className="field col-5 p-1 ml-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>FACTORES DE RIESGO</b></div>
                <div className="field col-2 p-1 mr-1 ml-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>VALOR</b></div>
                <div className="field col-2 p-1 mr-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>PUNTAJE</b></div>
                <div className="field col-2 p-1 mr-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>PONDERACIÓN</b></div>
            </>
        )
    }

    const cabeceraAcelerador = (op = true) => {
        return (
            <>
                <div className="field col-5 ml-0 mr-0 p-1 mb-0 text-white text-xs bg-blue-600 border-1" style={{ textAlign: 'center' }}><b>{op && "SEÑAL/ES DE ALERTA DETECTADA/S"}</b></div>
                <div className="field col-2 ml-0 mr-0 p-1 mb-0 text-white text-xs bg-blue-600 border-1" style={{ textAlign: 'center' }}><b>{op && 'VALOR'}</b></div>
                <div className="field col-2 ml-0 mr-0 p-1 mb-0 text-white" style={{ textAlign: 'center' }}></div>
                <div className="field col-2 ml-0 mr-0 p-1 mb-0 text-white" style={{ textAlign: 'center' }}></div>
            </>
        )
    }

    const scolor = (value, acolor) => {
        const val = !value ? 0 : parseFloat(value);
        const str = {};
        for (let col = 0; col < acolor.length; col++) {
            if (val <= parseFloat(acolor[col].maximo)) {
                str.bcolor = '#' + acolor[col].color;
                str.tcolor = '#' + acolor[col].color_texto;
                break;
            }
        }

        return str;
    }

    const pie = (label, tipo, matriz) => {
        return (
            <>
                <div className="field col-7 ml-1" style={{ textAlign: 'right', verticalAlign: 'middle' }} ><b>{label}</b></div>
                <div className="field col-2 mr-1 ml-1" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: matriz[tipo]?.color?.bcolor,
                    color: matriz[tipo]?.color?.tcolor, fontWeight: 'bold'
                }}><b><span id={tipo + "-puntaje"}>{matriz[tipo]?.puntaje}</span></b></div>
                <div className="field col-2 mr-1 ml-1 bg-blue-100" style={{ textAlign: 'center', verticalAlign: 'middle' }}><b>
                    <span id={tipo + "-ponderacion"}>{matriz[tipo]?.ponderacion} %</span></b></div>
            </>
        )
    }

    const factor = (field, listado, matriz, calcular, input = 'complete', search = null) => {
        const cols = field.split('-');
        return (
            <>
                {input === 'drop' && inDropDown(field, listado, 5, { lab: 'nombre', hd: matriz[cols[0]]?.nombre, chn: calcular, val: field, ml: ' ml-1' })}
                {input === 'complete' && inComplete(field, listado, search, {
                    col: 5, lab: 'nombre', hd: matriz[cols[0]]?.nombre, chn: calcular,
                    val: (matriz[cols[0]]?.nemonico + "-" + matriz[cols[0]]?.campo_valor), ml: ' ml-1'
                })}
                {input === 'number' && inNumber3(field, 5, { lab: 'nombre', hd: matriz[cols[0]]?.nombre, chn: calcular, ml: ' ml-1' })}
                <div className="field col-2 p-4 mr-1 ml-1 text-xs" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: matriz[cols[0]]?.color?.bcolor,
                    color: matriz[cols[0]]?.color?.tcolor, fontWeight: 'bold'
                }}>{matriz[cols[0]]?.valor}</div>
                <div className="field col-2 p-4 mr-1 bg-blue-100 text-xs" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{matriz[cols[0]]?.puntaje}</div>
                <div className="field col-2 p-4 mr-1 bg-blue-100 text-xs" style={{ textAlign: 'center', verticalAlign: 'middle' }}>{matriz[cols[0]]?.ponderacion} %</div>
            </>
        )
    }

    const acelerador = (matriz, des, src, field = null, listado = null, calcular = null) => {
        return (
            <>
                {!listado && des !== 'fcr23' && <div className="field ml-0 mr-0 mb-0 p-1 bg-gray-200 border-1 text-xs col-5">{matriz[des].nombre}</div>}
                {listado && des !== 'fcr23' && inDropDown(field, listado, 5, { lab: 'nombre', hd: matriz[des]?.nombre, chn: calcular, val: field })}
                {des === 'fcr23' && <div className="field ml-0 mr-0 mb-0 p-1 bg-gray-200 border-1 text-green-600 font-bold col-5 text-xs">{field}</div>}
                <div className="field col-2 ml-0 mr-0 mb-0 p-1 border-1 text-xs" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: matriz[src]?.color?.bcolor,
                    color: matriz[src]?.color?.tcolor, fontWeight: 'bold'
                }}>{matriz[src].valor}</div>
                <div className="field col-2 ml-0 mr-0 p-1 mb-0"></div>
                <div className="field col-2 ml-0 mr-0 p-1 mb-0"></div>
            </>
        );
    }

    const infoColor = (color) => {
        const rcolor = [];
        rcolor.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1" key={`ci-0`}>NIVEL RIESGO</div>);
        rcolor.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1" key={`ci-1`}>RANGO</div>);
        const ivalue = (val) => Math.round((parseFloat(val) + 0.1) * 10) / 10;

        for (let i = 0; i < color.length; i++) {
            const style = { backgroundColor: "#" + color[i].color, color: "#" + color[i].color_texto };
            rcolor.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1" style={style} key={`co-${i}`}>{color[i].nombre}</div>);
            rcolor.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1" key={`cr-${i}`}>{ivalue(color[i].minimo)} A {parseInt(color[i].maximo)}</div>);
        }

        return rcolor;
    }

    const infoSenialCli1 = (matriz, ini = 24, fin = 26) => {
        const info = [];
        for (let i = ini; i <= fin; i++) {
            const _col = `sen${i}`;
            const _color = i === fin ? '#000000' : matriz[_col]?.color?.bcolor;
            const _tcolor = i === fin ? matriz[_col]?.color?.bcolor : matriz[_col]?.color?.tcolor;
            info.push(<div className="field col-7 m-0 text-center text-xs font-bold border-1" key={`snom-${i}`}>{matriz[_col]?.nombre}</div>);
            info.push(<div className="field col-4 m-0 text-center text-xs font-bold border-1" style={{
                textAlign: 'center', verticalAlign: 'middle', backgroundColor: _color, color: _tcolor, fontWeight: 'bold'
            }} key={`sval-${i}`}>{matriz[_col]?.valor}</div>);
        }
        info.push(<div className="field col-7 m-0 text-center text-xs font-bold border-1" key="sval-27">
            <span style={{ top: '50%', position: 'relative', margin: '0', transform: 'translate(-50%, -50%)' }}>{matriz?.sen27?.nombre ?? 'CONTROLES APLICADOS:'}</span></div>);

        return info;
    }

    const infoSenialControl = (catalogos, calcular, cli = true) => {
        const info = [];
        info.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1" key="ctr1-27">CONTROL</div>);
        info.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1 p-0" key="ctr2-27">SELECCIONAR CALIFICACIÓN ↓</div>);
        const label = cli ? { 28: 'FORMULARIO / REGISTRO', 29: 'LICITUD FONDOS', 30: 'DOCUMENTACIÓN SUSTENTO', 31: 'VERIFICACIÓN LISTAS' }
            : { 12: 'FORMULARIO / REGISTRO', 13: 'DOCUMENTACIÓN SUSTENTO', 14: 'VERIFICACIÓN LISTAS' };
        Object.keys(label).map((key) => {
            const field = `sen${key}-${key}-valor`;
            info.push(<div className="field col-6 m-0 text-center text-xs font-bold border-1 p-0" key={`ctr-${key}`}>{label[key]}</div>);
            info.push(inDropDown(field, catalogos?.CONTROL, 6, {
                lab: 'nombre', obl: 'no', hd: '', input: true, ml: ' p-0 m-0',
                key: `ctrk-${key}`, chn: calcular, val: field
            }));
            return 1;
        });

        return info;
    }

    const infoSenial2 = (matriz) => {
        const info = [];
        for (let i = 36; i <= 38; i++) {
            const inx = `sen${i}`;
            const _color = [36, 37].includes(i) ? matriz['sen36']?.color?.bcolor : '#000000';
            const _tcolor = [36, 37].includes(i) ? matriz['sen36']?.color?.tcolor : matriz['sen36']?.color?.bcolor;
            info.push(<div className="field col-7 m-0 text-center text-xs font-bold border-1" key={`snom-${i}`}>{matriz[inx]?.nombre}</div>);
            info.push(<div className="field col-4 m-0 text-center text-xs font-bold border-1" key={`sval-${i}`}
                style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: _color, color: _tcolor, fontWeight: 'bold'
                }}>{matriz[inx]?.valor}</div>);
        }

        return info;
    }

    const infoSenialCli2 = (matriz, calcular) => {
        const info = [];
        const inx = [32, 33];
        for (let i = 0; i < inx.length; i++) {
            info.push(<div className="field col-9 m-0 text-center text-xs font-bold border-1" key={`ctr1-${inx[i]}`}>{matriz[`sen${inx[i]}`]?.nombre}</div>);
            info.push(<div className="field col-2 m-0 text-center text-xs font-bold border-1" key={`ctr2-${inx[i]}`}>{matriz[`sen${inx[i]}`]?.valor}</div>);
        }
        info.push(<div className="field col-9 m-0 text-center text-xs font-bold border-1" key="sval-34">
            <div className="formgrid grid justify-content-center" key="tval-34">
                <div className="field col-12 m-0 text-center text-xs font-bold border-1" key="pval-34" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#548235', color: matriz?.sen34?.color?.bcolor, fontWeight: 'bold'
                }}>{matriz?.sen34?.ponderacion}</div>
                <div className="field col-4 m-0 text-center text-xs font-bold border-1" key="sval-35" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#548235', color: matriz?.sen34?.color?.bcolor, fontWeight: 'bold'
                }}>{matriz?.sen35?.valor}</div>
                {matriz && inTextArea('sen35-35-puntaje', { cols: 4, rows: 2, col: 8, chn: calcular, req: matriz?.sen24?.valor > 3 ? 'Campo obligatorio' : false })}
            </div>
        </div>);
        const lista = [{ valor: 'SI', nombre: 'SI' }, { valor: 'NO', nombre: 'NO' }];
        info.push(inDropDown('sen34-34-valor', lista, 2, {
            lab: 'nombre', obl: 'no', hd: '', input: true, ml: ' p-0 m-0',
            key: `ctrk-34`, chn: calcular, val: 'sen34-34-valor'
        }));

        return info;
    }

    const infoSenialPro2 = (matriz, calcular) => {
        const info = [];
        const inx = [15, 16];
        for (let i = 0; i < inx.length; i++) {
            info.push(<div className="field col-9 m-0 text-center text-xs font-bold border-1" key={`ctr1-${inx[i]}`}>{matriz[`sen${inx[i]}`]?.nombre}</div>);
            info.push(<div className="field col-2 m-0 text-center text-xs font-bold border-1" key={`ctr2-${inx[i]}`}>{matriz[`sen${inx[i]}`]?.valor}</div>);
        }
        info.push(<div className="field col-7 m-0 text-center text-xs font-bold border-1" key="pval-17" style={{
            textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#548235', color: matriz?.sen17?.color?.bcolor, fontWeight: 'bold'
        }}>{matriz?.sen17?.ponderacion}</div>);
        const lista = [{ valor: 'SI', nombre: 'SI' }, { valor: 'NO', nombre: 'NO' }];
        info.push(inDropDown('sen17-17-valor', lista, 4, {
            lab: 'nombre', obl: 'no', hd: '', input: true, ml: ' p-0 m-0',
            key: `ctrk-17`, chn: calcular, val: 'sen17-17-valor'
        }));

        return info;
    }

    const proSenial2 = (matriz) => {
        const info = [];
        for (let i = 18; i <= 20; i++) {
            const inx = `sen${i}`;
            const _color = [18, 19].includes(i) ? matriz['sen18']?.color?.bcolor : '#000000';
            const _tcolor = [18, 19].includes(i) ? matriz['sen18']?.color?.tcolor : matriz['sen18']?.color?.bcolor;
            info.push(<div className="field col-7 m-0 text-center text-xs font-bold border-1" key={`snom-${i}`}>{matriz[inx]?.nombre}</div>);
            info.push(<div className="field col-4 m-0 text-center text-xs font-bold border-1" key={`sval-${i}`}
                style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: _color, color: _tcolor, fontWeight: 'bold'
                }}>{matriz[inx]?.valor}</div>);
        }

        return info;
    }

    const tablaAlertaPro = (matriz) => {
        return (
            <div className="formgrid grid justify-content-center">
                <div className="field col-2 m-0"></div>
                <div className="field col-2 border-1 m-0 text-center text-xs font-bold">FACTORES DE RIESGO</div>
                <div className="field col-2 border-1 m-0 text-center text-xs font-bold">PUNTAJES</div>
                <div className="field col-2 border-1 m-0 text-center text-xs font-bold">ALERTA</div>
                <div className="field col-2 border-1 m-0 text-center text-xs font-bold">PRIORIDAD DE ATENCIÓN</div>
                <div className="field col-2 m-0"></div>

                <div className="field col-2 m-0"></div>
                <div className="field col-2 border-1 m-0 text-center text-xs font-bold">PROVEEDOR</div>
                <div className="field col-2 border-1 m-0" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: matriz?.pro6?.color?.bcolor,
                    color: matriz?.pro6?.color?.tcolor, fontWeight: 'bold'
                }}>{matriz?.pro6?.puntaje}</div>
                <div className="field col-2 border-1 m-0" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: matriz?.pro6?.color?.bcolor,
                    color: matriz?.pro6?.color?.tcolor, fontWeight: 'bold'
                }}>{matriz?.ale8?.ponderacion}</div>
                <div className="field col-2 border-1 m-0" style={{
                    textAlign: 'center', verticalAlign: 'middle', backgroundColor: matriz?.pro6?.color?.bcolor,
                    color: matriz?.pro6?.color?.tcolor, fontWeight: 'bold'
                }}>{matriz?.ale8?.puntaje}</div>
                <div className="field col-2 m-0"></div>
            </div>
        );
    }

    return {
        inDropAnio,
        inNumber2,
        numericRound,
        resume,
        generateRange,
        colorSenialDos,
        onError,
        buttonsMatriz,
        inCalendarOne,
        inDropDown,
        inComplete,
        cabecera,
        scolor,
        pie,
        factor,
        inNumber3,
        cabeceraAcelerador,
        acelerador,
        infoColor,
        infoSenialCli1,
        infoSenialControl,
        infoSenialCli2,
        inTextArea,
        infoSenial2,
        infoSenialPro2,
        proSenial2,
        tablaAlertaPro
    }
}
