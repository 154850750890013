import { FilterMatchMode } from "primereact/api"

export class Usuarios {
    model = {
        id: {value: null, export: false},
        login: {value: '', export: true, header: 'Logín', required: 'Nombre de usuario es requerido', maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 400, min: 4, filterField: 'login', table: true, filter: true, sort: true,
            filterPlaceholder: 'Buscar por logín', matchMode: FilterMatchMode.CONTAINS},
        empresa: {value: null, export: false, header: 'Empresa', required: 'Empresa es requerido',
            filterField:'nombre', table: true, filter: true, sort: true, filterPlaceholder:'Buscar por Empresa', matchMode: FilterMatchMode.CONTAINS},
        identificacion: {value: '', export: true, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 400, min: 4, filterField: 'identificacion', table: true, filter: true, sort: true,
            filterPlaceholder: 'Buscar por identificacion', matchMode: FilterMatchMode.CONTAINS},
        nombre: {value: '', export: true, header: 'Nombres completos', required: 'Nombre es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 400, min: 4, filterField: 'nombre', table: true, filter: true, sort: true,
            filterPlaceholder: 'Buscar por nombres', matchMode: FilterMatchMode.CONTAINS},
        email: {value: '', export: true, header: 'Correo electrónico', required: 'Email es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'email', table: true, filter: false, sort: true},
        estado: {header: 'Habilitar', value: true, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
        google_2fa_enabled: { header: 'Habilitar Doble Autenticación', value: false, export: true, table: false },
        enviar: {header: 'Enviar correo', value: {nombre: 'SI', cat_id: true}, required: 'Campo requerido', optionLabel: 'nombre', export: false, table: false},
    }
    optionLabel = 'nombre'
}
