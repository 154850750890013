import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Toolbar } from "primereact/toolbar"
import { PlanCatalogo } from "../../models/matriz/PlanCatalogo";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { PlanCatalogoService } from "../../service/matriz/PlanCatalogoService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import AdjuntoService from "../../service/AdjuntoService";
import { Dropdown } from "primereact/dropdown";
import { Generico } from "../../components/Generico";

export const ItemFactor = ({ generic, fact, toast, permss, setRegresar }) => {

    let fileRef = useRef(null);
    const omodel = new PlanCatalogo();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const catalogoSrv = new PlanCatalogoService();
    const { accionesTbl } = Generico();
    const adjuntoSrv = new AdjuntoService();
    const [dialogCatalogo, setDialogCatalogo] = useState(false);
    const [catalogo, setCatalogo] = useState(null);
    const [dialogCarga, setDialogCarga] = useState(false);
    const [dialogDescargar, setDialogDescargar] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const [valor, setValor] = useState(null);
    const [load, setLoad] = useState(false);
    const [item, setItem] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idp = fact?.id;
            delete _lazyParams.catalogo;
            if (catalogo == null) {
                _lazyParams.catalogo = 'consultar';
            }
            catalogoSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                if (catalogo == null) {
                    setCatalogo(res.data.catalogo);
                }
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        setItem(null);
        reset(defaultValues);
        setDialogCatalogo(true);
    }

    const edit = () => {
        catalogoSrv.consultar(item?.id, fact?.id).then(res => {
            const lista = { estado: generic.estados, seccion: catalogo?.seccion, color: catalogo?.color, tipo_analisis: catalogo?.analisis, };
            let _plan = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_plan[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _plan[key]) {
                            _plan[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_plan, omodel.model));
            setDialogCatalogo(true);
        })
    }

    const onSubmit = (data) => {
        data.idp = fact?.id;
        data.con_catalogo = fact?.con_catalogo;
        if (getValues('id') == null) {
            catalogoSrv.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogCatalogo(false);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            catalogoSrv.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogCatalogo(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar el ítem?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                catalogoSrv.eliminar(item?.id, fact?.id).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_PEP_SIZE);
        if (file.size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1000) + " Kb", life: 3000 });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_PEP_SIZE);
        if (e.files[0].size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1000) + " Kb", life: 3000 });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const descargar = () => {
        if (valor === null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el campo del valor.', life: 3000 });
            return;
        }
        catalogoSrv.exportar(fact?.id, fact?.nemonico, valor?.cat_id).then(res => {
            generic.exportFileExcel('Listado', res.data);
        });
    }

    const startContent = (
        <>
            {fact?.con_catalogo === false && <Button icon="pi pi-plus" className="mr-2" onClick={openNew} title="Nuevo ítem" />}
            <Button icon="pi pi-upload" onClick={() => subir(true)} title="Cargar archivo" />&nbsp;
            <Button icon="pi pi-download" onClick={() => setDialogDescargar(true)} title="Descargar archivo" />
        </>
    );

    const subir = () => {
        setArchivo(null);
        setValor(null);
        setDialogCarga(true);
    }

    const subida = () => {
        if (archivo === null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        if (valor === null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el campo del valor.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo, archivo.name);
        formData.append('id', fact?.id);
        formData.append('valor', valor?.cat_id);
        const unuevo = 'matriz/catalogo/individual';
        setLoad(true);
        adjuntoSrv.newAdjunto(formData, unuevo).then(res => {
            setLoad(false);
            setDialogCarga(false);
            setArchivo(null);
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            setLoad(false);
            generic.funcDeleteError(error, toast, setError);
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar factor', icon: 'pi pi-pencil', command: () => { edit() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <Card style={{ maxWidth: '100%', margin: 'auto' }} title={"Listado factor: " + fact?.nombre}>
                <Toolbar className="mb-4" start={startContent}></Toolbar>
                {table({ action01: accionesTbl, reg: setItem, items: itemsMenu() })}

                <br />
                <Button label="Regresar" icon="pi pi-arrow-left" className="p-button-success mr-2" type="button" onClick={() => setRegresar(false)} />
            </Card>

            <Dialog visible={dialogCatalogo} header={getValues('id') == null ? "Nueva plantilla" : "Editar plantilla"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogCatalogo(false)} style={{ maxWidth: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', { readOnly: fact?.con_catalogo }, 12)}
                        {inText('codigo', {}, 6)}
                        {inNumber('valor1', 2, false, {}, 6)}
                        {inNumber('valor2', 2, false, {}, 6)}
                        {inNumber('valor3', 2, false, {}, 6)}
                        {inNumber('valor4', 2, false, {}, 6)}
                        {inNumber('valor5', 2, false, {}, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                    </div>

                    {generic.buttonsForms(setDialogCatalogo)}
                </form>
            </Dialog>

            <Dialog visible={dialogCarga} header="Subir archivo" modal className="p-fluid modal-ssize"
                onHide={e => setDialogCarga(false)} style={{ maxWidth: '450px' }}>
                <br />
                <div className="formgrid grid">
                    <div className="field col-6">
                        <b>Seleccione un archivo*</b><br />
                        <FileUpload name="file" accept=".xlsx" maxFileSize={205000} uploadHandler={invoiceUploadHandler} mode="basic"
                            customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} tooltip={omodel.insCarga} />

                        {archivo?.name}
                    </div>
                    <div className="field col-6">
                        <b>Valor a asignarse*</b>
                        <Dropdown value={valor} onChange={(e) => setValor(e.value)} options={omodel.valores} optionLabel="nombre"
                            placeholder="Seleccione el campo para el valor" className="w-full md:w-14rem" filter showClear filterBy="nombre" />
                    </div>

                    <div className="field col-12" style={{ textAlign: 'center' }}>
                        <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="button" icon="pi pi-check" className="p-button-warning" onClick={() => subida()} loading={load} />
                    </div>
                </div>
            </Dialog>

            <Dialog visible={dialogDescargar} header="Descargar archivo" modal className="p-fluid modal-ssize"
                onHide={e => setDialogDescargar(false)} style={{ maxWidth: '450px' }}>
                <br />
                <div className="formgrid grid">
                    <div className="field col-6">
                        <b>Valor a asignarse*</b>
                        <Dropdown value={valor} onChange={(e) => setValor(e.value)} options={omodel.valores} optionLabel="nombre"
                            placeholder="Seleccione el campo para el valor" className="w-full md:w-14rem" filter showClear filterBy="nombre" />
                    </div>

                    <div className="field col-12" style={{ textAlign: 'center' }}>
                        <Button label="Descargar" style={{ maxWidth: '120px', margin: 'auto' }} type="button" icon="pi pi-check" className="p-button-warning" onClick={() => descargar()} loading={load} />
                    </div>
                </div>
            </Dialog>
        </>
    )
}
