import { DetailedCellError, HyperFormula } from "hyperformula";
import { useEffect, useRef, useState } from "react";
import { InfoAccionistaService } from "../../service/matriz/InfoAccionistaService";
import { useForm } from "react-hook-form";
import { Generico } from "../../components/Generico";
import { MatrizComponent } from "../../components/MatrizComponent";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';

export const DetalleAccionistaJuridicoDos = ({ matrizId, permss, setDialogPerfil, toast, regresar }) => {
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({});
    const accionistaSrv = new InfoAccionistaService();
    const [rango, setRango] = useState(null);
    const [matriz, setMatriz] = useState(null);
    const hfInstanceRef = useRef(null);
    const [loadSave, setLoadSave] = useState(false);
    const [loadCambio, setLoadCambio] = useState(false);
    const { funcError, funcSuccess, funcMessageBlobError, downloadPdf } = Generico();
    const [loadReport, setLoadReport] = useState(false);
    const { inDropAnio, inNumber2, numericRound, resume, generateRange, onError, buttonsMatriz } = MatrizComponent({ control, errors, permss, toast });
    const [loading, setLoading] = useState(false);
    const [loadingDatos, setLoadingDatos] = useState(true);

    useEffect(() => {
        hfInstanceRef.current = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' });
        hfInstanceRef.current.addSheet('Sheet1');
        generateRange(setRango);
        verificarMatriz();
        return () => {
            hfInstanceRef.current.destroy();
        };
    }, [matrizId]);

    const onSubmit = (data) => {
        if (matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede guardar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadSave(true);
        const _matriz = matriz;
        accionistaSrv.detalle(_matriz, 3, 'j').then(res => {
            setLoadSave(false);
            funcSuccess(res, toast);
        }).catch(error => {
            setLoadSave(false);
            funcError(error, toast, setError);
        });
    }

    const finalizar = () => {
        if (matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede finalizar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadCambio(true);
        accionistaSrv.finalizar({ id: matrizId, estado: 'BORRADOR', cambio: 'a' }, 3, 'j').then(res => {
            setLoadCambio(false);
            funcSuccess(res, toast);
            regresar();
        }).catch(error => {
            setLoadCambio(false);
            funcError(error, toast, setError);
        });
    }

    const verificarMatriz = () => {
        accionistaSrv.informacion(matrizId, 3, 'j').then(res => {
            const _info = res.data;
            valueCell('anio1sel', _info?.anio1, 0);
            valueCell('anio2sel', _info?.anio2, 0);
            const _values = {};
            _values.anio1 = _info?.anio1 ? { nombre: _info?.anio1 + "", cat_id: _info?.anio1 } : null;
            _values.anio2 = _info?.anio2 ? { nombre: _info?.anio2 + "", cat_id: _info?.anio2 } : null;
            const data = [];
            const formulas = [];
            let index = 0;
            Object.keys(_info).map((key) => {
                const siMatriz = key.includes('eco') || key.includes('pat');
                if (siMatriz) {
                    const orden = _info[key].orden;
                    if (_info[key]?.color.includes('MATRIZ_COLOR_CELESTE')) {
                        _values[`${key}-${orden}-valor_anio1`] = parseInt(_info[key]?.valor_anio1);
                        _values[`${key}-${orden}-valor_anio2`] = parseInt(_info[key]?.valor_anio2);
                    }
                    data.push([_info[key]?.valor_anio1, _info[key]?.valor_anio2, _info[key]?.calculado1, _info[key]?.calculado2, _info[key]?.calculado3]);
                    if (_info[key]?.anio1) formulas.push({ value: _info[key]?.anio1, row: index, col: 0 });
                    if (_info[key]?.anio2) formulas.push({ value: _info[key]?.anio2, row: index, col: 1 });
                    if (_info[key]?.formula1) formulas.push({ value: _info[key]?.formula1, row: index, col: 2 });
                    if (_info[key]?.formula2) formulas.push({ value: _info[key]?.formula2, row: index, col: 3 });
                    if (_info[key]?.formula3) formulas.push({ value: _info[key]?.formula3, row: index, col: 4 });
                    delete _info[key]?.formula3;
                    delete _info[key]?.formula2;
                    delete _info[key]?.formula1;
                    delete _info[key]?.anio1;
                    delete _info[key]?.anio2;
                    index++;
                }

                return 1;
            });

            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: 0, row: 0 },
                data
            );
            formulas.forEach((formula) => {
                hfInstanceRef.current.setCellContents(
                    { sheet: 0, col: formula.col, row: formula.row },
                    [[formula.value]]
                );
            });
            setMatriz(_info);
            reset(_values);
        }).catch(error => {
            funcError(error, toast, setError);
        }).finally(() => setLoadingDatos(false));
    }

    const onChangeRango = (e, name) => {
        valueCell(`${name}sel`, e.value?.cat_id, 0);
        setMatriz(prevItem => ({
            ...prevItem,
            [name]: e.value?.cat_id
        }));
    };

    const valueCell = (field, value, row) => {
        const cols = { valor_anio1: 0, valor_anio2: 1, calculado1: 2, calculado2: 3, calculado3: 4, anio1: 0, anio2: 1, formula1: 2, formula2: 3, formula3: 4, anio1sel: 11, anio2sel: 12 };
        if (value) {
            hfInstanceRef.current.setCellContents({ sheet: 0, col: cols[field], row: row }, [[value]]);
        }
        const calculatedValue = hfInstanceRef.current.getCellValue({ sheet: 0, col: cols[field], row: row });
        if (calculatedValue instanceof DetailedCellError) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${calculatedValue.type}, Message: ${calculatedValue.message}, field: ${field}, value: ${value}, row: ${row}`, life: 3000 });
            return null;
        }

        return numericRound(calculatedValue) + (field === 'calculado2' && typeof calculatedValue === 'number' ? ' %' : '');
    }

    const calcular = (value, name) => {
        const siNumber = name.includes('eco') || name.includes('pat');
        if (siNumber) {
            const _matriz = matriz;
            delete _matriz.inicio;
            setMatriz(_matriz);
        }
        const [key, orden, field] = name.split('-');
        const rrow = parseInt(orden) - 1;
        const _item = matriz[key];
        _item[field] = valueCell(field, value, rrow);
        _item.calculado1 = valueCell('calculado1', null, rrow);
        _item.calculado2 = valueCell('calculado2', null, rrow);
        _item.calculado3 = valueCell('calculado3', null, rrow);
        setMatriz(prevItem => ({
            ...prevItem,
            [key]: _item
        }));
        const arrs = [3, 4, 7, 10];
        const _cols = ['valor_anio1', 'valor_anio2', 'calculado1', 'calculado2', 'calculado3'];
        arrs.forEach(itm => {
            const ftl = itm === 3 ? 'eco' : 'pat';
            const _cal = matriz[`${ftl}${itm}`];
            _cols.forEach(col => {
                const res = valueCell(col, null, itm - 1);
                _cal[col] = res;
                if (res === null) {
                    return;
                }
            });
        });
    }

    const generarReporte = () => {
        if (matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede generar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadReport(true);
        accionistaSrv.descargar(matrizId, 3, 'j').then(res => {
            downloadPdf(res, 'Reporte-Matriz.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Asegúrese de ingresar y guardar la información antes de generar el reporte.', error, toast);
        });
    }

    const infoEconomica = (ini, fin, tipo) => {
        const info = [];
        for (let num = ini; num <= fin; num++) {
            const bg2 = matriz[`${tipo}${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? 'blue-100' : 'black-alpha-60';
            let tc = '';
            let bg1 = null;
            if (tipo === 'eco') {
                tc = matriz[`${tipo}${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? '' : 'text-white';
                bg1 = matriz[`${tipo}${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? 'yellow-100' : 'black-alpha-60';
            }
            info.push(resume((num * 100) + 1, '', 1, 0, false, 'xs', 'center', null, null));
            info.push(resume((num * 100) + 2, matriz[`${tipo}${num}`].nombre, 3, 0, true, 'xs', 'center', bg1, `border-1 ${tc}`));
            tc = matriz[`${tipo}${num}`].color.includes('MATRIZ_COLOR_CELESTE') ? '' : 'text-white';
            if (matriz[`${tipo}${num}`].color.includes('MATRIZ_COLOR_CELESTE')) {
                info.push(inNumber2(`${tipo}${num}-${num}-valor_anio1`, 2, { chn: calcular, key: (num * 100) + 3 }));
                info.push(inNumber2(`${tipo}${num}-${num}-valor_anio2`, 2, { chn: calcular, key: (num * 100) + 4 }));
            } else {
                info.push(resume((num * 100) + 3, matriz[`${tipo}${num}`].valor_anio1, 2, 0, false, 'xs', 'center', bg2, `border-1 ${tc}`));
                info.push(resume((num * 100) + 4, matriz[`${tipo}${num}`].valor_anio2, 2, 0, false, 'xs', 'center', bg2, `border-1 ${tc}`));
            }
            info.push(resume((num * 100) + 5, matriz[`${tipo}${num}`].calculado1, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1'));
            info.push(resume((num * 100) + 6, matriz[`${tipo}${num}`].calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1'));
            info.push(resume((num * 100) + 7, matriz[`${tipo}${num}`].calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1'));
        }

        return info;
    }

    const buscarInformacion = () => {
        const anio1 = matriz?.anio1;
        const anio2 = matriz?.anio2;

        if (!anio1 || !anio2) {
            toast.current.show({ severity: 'info', summary: 'Información', detail: 'Seleccione ambos periodos antes de buscar datos financieros.', life: 3000 });
            return;
        }

        setLoading(true);
        accionistaSrv.informacion(matrizId, 3, 'j', anio1, anio2).then(res => {
            const _info = res.data;
            procesarDatos(_info);
            if (_info.detalleMensajes && Array.isArray(_info.detalleMensajes)) {
                _info.detalleMensajes.forEach(detalle => {
                    toast.current.show({
                        severity: 'success', summary: detalle.titulo, sticky: true,
                        content: (props) => (
                            <div className="flex flex-column p-3">
                                <div className="flex align-items-center gap-2">
                                    <span className="font-bold text-800 text-lg">{props.message.summary}</span>
                                </div>
                                <div className="text-800 mt-2">
                                    <p className="m-0"><strong>📅 Período solicitado:</strong> {detalle.anio_solicitado}</p>
                                    <p className="m-0"><strong>📊 Año de datos encontrado:</strong> {detalle.anio_encontrado}</p>
                                    <p className="m-0"><strong>🗓️ Fecha de análisis:</strong> {detalle.fecha_analisis}</p>
                                </div>
                                <div className="mt-3">
                                    <p className="text-base text-900 font-semibold">{detalle.mensaje}</p>
                                </div>
                            </div>
                        )
                    });
                });
            } else {
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: "Se han encontrado datos financieros", life: 4000 });
            }
            setLoading(false);
        }).catch(error => {
            const errorMessage = error.response?.data?.message || "No se pudo importar la información";
            toast.current.show({ severity: 'warn', summary: 'Sin datos', detail: errorMessage, life: 3000 });
            setLoading(false);
        });
    }

    const procesarDatos = (_info) => {
        valueCell('anio1sel', _info?.anio1, 0);
        valueCell('anio2sel', _info?.anio2, 0);
        const _values = {};
        _values.anio1 = _info?.anio1 ? { nombre: _info?.anio1 + "", cat_id: _info?.anio1 } : null;
        _values.anio2 = _info?.anio2 ? { nombre: _info?.anio2 + "", cat_id: _info?.anio2 } : null;
        const data = [];
        const formulas = [];
        let index = 0;
        Object.keys(_info).map((key) => {
            const siMatriz = key.includes('eco') || key.includes('pat');
            if (siMatriz) {
                const orden = _info[key].orden;
                if (_info[key]?.color.includes('MATRIZ_COLOR_CELESTE')) {
                    _values[`${key}-${orden}-valor_anio1`] = parseInt(_info[key]?.valor_anio1);
                    _values[`${key}-${orden}-valor_anio2`] = parseInt(_info[key]?.valor_anio2);
                }
                data.push([_info[key]?.valor_anio1, _info[key]?.valor_anio2, _info[key]?.calculado1, _info[key]?.calculado2, _info[key]?.calculado3]);
                if (_info[key]?.anio1) formulas.push({ value: _info[key]?.anio1, row: index, col: 0 });
                if (_info[key]?.anio2) formulas.push({ value: _info[key]?.anio2, row: index, col: 1 });
                if (_info[key]?.formula1) formulas.push({ value: _info[key]?.formula1, row: index, col: 2 });
                if (_info[key]?.formula2) formulas.push({ value: _info[key]?.formula2, row: index, col: 3 });
                if (_info[key]?.formula3) formulas.push({ value: _info[key]?.formula3, row: index, col: 4 });
                delete _info[key]?.formula3;
                delete _info[key]?.formula2;
                delete _info[key]?.formula1;
                delete _info[key]?.anio1;
                delete _info[key]?.anio2;
                index++;
            }

            return 1;
        });

        hfInstanceRef.current.setCellContents(
            { sheet: 0, col: 0, row: 0 },
            data
        );
        formulas.forEach((formula) => {
            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: formula.col, row: formula.row },
                [[formula.value]]
            );
        });
        // Parte del método 'calcular', se recorren los datos para hacer nuevos cálculos
        const updatedMatriz = { ..._info };
        Object.keys(updatedMatriz).forEach((key) => {
            const siMatriz = key.includes('eco') || key.includes('pat') || key.includes('sep') || key.includes('ses');
            if (siMatriz) {
                const item = updatedMatriz[key];
                const rrow = item.orden - 1;
                item.calculado1 = valueCell('calculado1', null, rrow);
                item.calculado2 = valueCell('calculado2', null, rrow);
                item.calculado3 = valueCell('calculado3', null, rrow);
            }
        });
        setMatriz(updatedMatriz);
        reset(_values);
    }

    return (
        <>
            {loadingDatos ? (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", textAlign: "center", gap: "20px" }}>
                    <h2 style={{ fontSize: "16px", fontWeight: "bold", textTransform: "uppercase", letterSpacing: "1px" }}>
                        Cargando datos...
                    </h2>
                    <ProgressSpinner style={{ width: '70px', height: '70px' }} strokeWidth="6" fill="var(--surface-ground)" animationDuration="4s" />
                </div>
            ) : (
                <Panel header="Matriz" style={{ maxWidth: '800px', margin: 'auto' }} className="panel-ficha">
                    <div className="grid grid-nogutter">
                        {resume(1, matriz?.empresa, 12, 1, true, '2xl')}
                        {resume(2, matriz?.nombre_plantilla, 12, 1, true, '2xl')}
                        {resume(3, `FECHA DE ANÁLISIS: ${matriz?.fecha}`, 12, 1, true, null, 'center', null, 'border-bottom-1')}
                        {resume(4, 'INFORMACIÓN DEL ACCIONISTA', 12, 0, true, '2xl', 'center', null, 'border-bottom-1')}
                        {resume(5, 'NOMBRE/RAZÓN SOCIAL:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                        {resume(6, matriz?.nombres, 7, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                        {resume(7, 'IDENTIFICACIÓN:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                        {resume(8, matriz?.identificacion, 5, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                    </div>
                    <hr />

                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="formgrid grid">
                            <div className="field col-4" style={{ marginTop: '1.85rem' }}>
                                <Button
                                    label={loading ? "Buscando información...." : "Buscar datos financieros"}
                                    icon={loading ? "pi pi-spinner pi-spin" : "pi pi-search-plus"}
                                    className="p-button-rounded"
                                    onClick={buscarInformacion}
                                    type="button"
                                    disabled={loading}
                                />
                            </div>
                            {inDropAnio('anio1', rango, { lab: 'nombre', chn: onChangeRango, header: 'Período 1' }, 2)}
                            {inDropAnio('anio2', rango, { lab: 'nombre', chn: onChangeRango, header: 'Período 2' }, 2)}
                            <div className="field col-4"></div>
                        </div>
                        <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                            {resume(9, '', 1, 0, true, null, 'center', null, null)}
                            {resume(10, 'INFORMACIÓN ECONÓMICA DEL ACCIONISTA', 11, 0, true, null, 'center', null, 'border-1', 1)}

                            {resume(11, '', 1, 0, true, null, 'center', null, null)}
                            {resume(12, 'CUENTAS:', 3, 0, true, 'xs', 'center', null, 'border-1')}
                            <div className="field col-4 m-0 border-1">
                                <div className="grid grid-nogutter">
                                    {resume(13, 'PERIODOS', 12, 0, true, 'xs', 'center', null, null)}
                                    {resume(14, matriz?.anio1, 6, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                                    {resume(15, matriz?.anio2, 6, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                                </div>
                            </div>
                            {resume(16, 'DIFERENCIA', 1, 0, true, 'xs', 'center', null, 'border-1')}
                            {resume(17, 'PORCENTAJE DE:', 3, 0, true, 'xs', 'center', null, 'border-1')}
                            {matriz && infoEconomica(1, 3, 'eco')}
                        </div>
                        <br />

                        <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                            {resume(9, '', 1, 0, true, null, 'center', null, null)}
                            {resume(10, 'INFORMACIÓN PATRIMONIAL DECLARADA POR EL ACCIONISTA', 11, 0, true, null, 'center', null, 'border-1', 1)}

                            {resume(11, '', 1, 0, true, null, 'center', null, null)}
                            {resume(12, 'CUENTAS:', 3, 0, true, 'xs', 'center', null, 'border-1')}
                            <div className="field col-4 m-0 border-1">
                                <div className="grid grid-nogutter">
                                    {resume(13, 'PERIODOS', 12, 0, true, 'xs', 'center', null, null)}
                                    {resume(14, matriz?.anio1, 6, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                                    {resume(15, matriz?.anio2, 6, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                                </div>
                            </div>
                            {resume(16, 'DIFERENCIA', 1, 0, true, 'xs', 'center', null, 'border-1')}
                            {resume(17, 'PORCENTAJE DE:', 3, 0, true, 'xs', 'center', null, 'border-1')}
                            {matriz && infoEconomica(4, 10, 'pat')}
                        </div>

                        <br />
                        {buttonsMatriz({
                            permss, btn1: { setDlg: setDialogPerfil }, btn2: { loadSave }, btn3: { loadReport, chn: generarReporte },
                            btn4: { msg: <>Verifique que la matriz esté guardada (Imprimir).<br />¿Desea finalizar la matriz?</>, chn: finalizar, loadCambio }
                        })}
                    </form>
                </Panel>
            )}
        </>
    )
}
